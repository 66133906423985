
import {defineComponent} from 'vue';
import { mapGetters, mapActions } from 'vuex';
import { ActionTypes } from '@/store/modules/order/actions';
import { ActionTypes as AuthActionTypes } from '@/store/modules/auth/actions';
import printDiv from '@/utils/print';
import { BarChart } from "vue-chart-3";
import { Chart, registerables } from "chart.js";
import PrintHeader from '@/components/common-components/PrintHeader.vue'
import {styles2 as styles} from '@/constants/analytics_print'
import { store } from "@/store";
import chartConfig from "@/constants";

export default defineComponent({
  name: 'InventoryAnaltyics',
   components: {
    BarChart,
    PrintHeader,
  },
  data() {
    const date = new Date();
    const dateStr = `${date.getFullYear()}-${date.getMonth()+1}-${date.getDate()}`;
    return {
      src_img: require('@/assets/DefoultLogoAvatar-01.png'),
      startDate: dateStr,
      endDate: dateStr,
      company: 0,
      chartData: {},
    };
  },
  computed: {
    ...mapGetters({
      analytics: 'getAnalytics',
      companies: 'getInventoryCompanies',
      userdata: 'getUser',
      logoimg: 'getLogoImg',
    }),
    admin(){
      const allowedRoles = ['SUPER_ADMIN', 'ADMIN'];
      const allowedCompanies = ['PARENT', 'STORE'];
      if(this.userdata != null && allowedRoles.includes(this.userdata.user_type)
        && allowedCompanies.includes(this.userdata.company.company_type) 
      ){
        return true;
      }
      return false;
    },
  },
  methods: {
    ...mapActions({
      fetchAnalytics: ActionTypes.FETCH_ANALYTICS,
      fetchCompanies: AuthActionTypes.FETCH_ALL_COMPANIES,
      fetchUser: AuthActionTypes.USER_DATA,
    }),
    async fetchAnalyticsBtn() {
      await this.fetchAnalytics({
        start_date: this.startDate,
        end_date: this.endDate,
        company: this.company,
      });
      const data = JSON.parse(JSON.stringify(store.getters.getAnalytics.category_stats));
      const graph_data=[]
      for (const key in data) {
          graph_data.push({x:key,y:data[key].quantity})
      }
      this.chartData = {
        labels: [],
        datasets: [
          {
            label: "Category",
            data: graph_data,
            backgroundColor: ['#f46a9b', '#00bfa0', '#0bb4ff', '#e60049', '#b33dc6'],
            borderColor: ['#f46a9b', '#00bfa0', '#0bb4ff', '#e60049', '#b33dc6'],
          },
        ],
        options: {
    parsing: {
        key: 'index.quantity',
    }
}
      };
    },
    getCurrentTime(){
			return new Date().toLocaleTimeString();
		},

		getCurrentDate(){
			const current = new Date()
      return current.toLocaleDateString()
		},
    printDiv(div_id: string, title: string) {
      const canvas = document.getElementById('bar-chart') as HTMLCanvasElement;
      const img = canvas.toDataURL('image/png')
      const graph_img = document.getElementById('graph-img') as HTMLImageElement
      graph_img.src = img
      printDiv(div_id, title, styles);
    },
  },
  async mounted() {
    await this.fetchUser();
    await this.fetchCompanies();
    await this.fetchAnalyticsBtn();
    this.company = this.userdata.company.id;
  }
})
