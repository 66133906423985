
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';

import { Company } from '@/store/models/company';
import { ActionTypes } from '@/store/modules/auth/actions';

export default defineComponent({
  name: 'DepartmentAddEdit',
  props: ['companyId'],
  data () {
    return {
      company: {
        name: '',
        address: '',
        contactNumber: '',
        type: 'FACTORY',
      }, 
      showErrorName: false,
    }
  },
  computed: {
    comapanyNameValidation: function () {
      let errorMessage = null;
      if (this.company.name.length <= 0 && this.showErrorName == true) {
        errorMessage = "Name is required"
      }
      return errorMessage;
    },

    contactNumberValidation: function () {
      let errorMessage = null;
      if (this.company.contactNumber !== '') {
        const regex = /^((\+92)|(0092))-{0,1}\d{3}-{0,1}\d{7}$|^\d{11}$|^\d{3,5}-{0,1}\d{7}$/;
        if (!regex.test(this.company.contactNumber)) {
          errorMessage = "Phone number is not valid"
        }
      }
      return errorMessage;
    },

    addEditBtn:  function () {
      let disable = true;
      if (this.comapanyNameValidation === null &&
      this.contactNumberValidation === null) {
        disable = false
      }
      return disable;
    },

    ...mapGetters({
      companyTypes: 'getCompanyTypes',
      userdata: 'getUser',
    })
  },
  methods: {
    addUpdateDepartment: async function () {
      this.showErrorName = true;
      let companyIdNumber = 0;
      if(this.comapanyNameValidation === null){

      if (this.companyId) {
        companyIdNumber = parseFloat(this.companyId);
        if (isNaN(companyIdNumber)) return;
      }

      const company: Company = {
        company_name: this.company.name,
        company_type: this.company.type,
        address: this.company.address,
        contact_number: this.company.contactNumber,
      };

      if (this.companyId) {
        company.id = companyIdNumber;
        await this.updateCompany(company);
      } else {
        if (this.userdata && this.userdata.company) {
          if (this.userdata.company.company_type === 'PARENT') {
            company.parent = this.userdata.company.id;
          } else {
            company.parent = this.userdata.company.parent;
          }
          await this.createCompany(company);
        }
      }
      
      this.$router.push({name: 'department'});
      }
    },

    loadData: function (company: Company) {
      this.company.name = company.company_name ? company.company_name : '';
      this.company.type = company.company_type ? company.company_type : '';
      this.company.address = company.address? company.address : '';
      this.company.contactNumber = company.contact_number? company.contact_number : '';
    },

    ...mapActions({
      createCompany: ActionTypes.CREATE_COMPANY,
      updateCompany: ActionTypes.UPDATE_COMPANY,
      getCompaniesList: ActionTypes.FETCH_COMPANIES,
      fetchTypes: ActionTypes.FETCH_TYPES,
      getUserData: ActionTypes.USER_DATA,
    })
  },
  async beforeMount () {
    await this.fetchTypes();
    await this.getUserData()

    if (this.companyId) {
      await this.getCompaniesList({
        company_type: '',
        search: ''
      });
      const company_id = parseInt(this.companyId);
      const company = isNaN(company_id) ? undefined : this.$store.getters.getSignleCompany(company_id);
      if (company) {
        this.loadData(company);
      }
      else {
        // Show 404 page on screen
        this.$router.push({name: 'notFound'});
      }
    }
  }
});
