
import { defineComponent } from 'vue';
import { mapGetters, mapActions } from 'vuex';
import { ActionTypes } from '@/store/modules/order/actions';

export default defineComponent({
  name: 'Expense',
  data() {
    const path = window.location.pathname;
    return {
      tab: path.split('/')[2],
      user_type: "",
    }
  },
  computed: {
     ...mapGetters({
      userdata: 'getUser',
    })
  },
  methods: {
    onClickOrder(){
      this.tab = 'order'
      this.$router.push({ name: "order-analytics" });
    },
    onClickInventory(){
      this.tab = 'inventory'
      this.$router.push({ name: "inventory-analytics" });
    },
    onClickDetails(){
      this.tab = 'detail'
      this.$router.push({ name: "operator-sales-detail" });
    },
    onClickReport(){
      this.tab = 'report'
      this.$router.push({ name: "profit-loss-report" });
    },
    onClickStockStatement(){
      this.tab = 'StockStatement'
      this.$router.push({ name: "StockStatement" });
    },
    onClickEndOfDay(){
      this.tab = 'End of Day'
      this.$router.push({ name: "end-of-day" });
      },
    onClickTotalReceivable(){
      this.tab = 'TotalReceivable'
      this.$router.push({ name: "TotalReceivable" });
    },
    onClickDayTotalPayable(){
      this.tab = 'dayTotalPayable'
      this.$router.push({ name: "DayTotalPayable" });
    },
    onClickComparisonAnalysis(){
      this.tab = 'comparisonAnalysis'
      this.$router.push({ name: "ComparisonAnalysis" });
    },
    onClickComparisonExpenseSales(){
      this.tab = 'comparisonExpenseSales'
      this.$router.push({ name: "ComparisonExpenseSales" });
    },

  },
  mounted () {
    this.user_type = this.userdata['user_type'];
  }
});
