
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import { ActionTypes } from '@/store/modules/auth/actions';
import { Transaction } from '@/store/models/transaction';
import { User } from '@/store/models/user';
import { Company } from '@/store/models/company';
import Paginator from '@/components/common-components/Paginator.vue';
import printDiv from '@/utils/print';
import {styles1 as styles} from '@/constants/analytics_print'
import PrintHeader from '@/components/common-components/PrintHeader.vue'

export default defineComponent({
  name: 'ExpenseSummary',
  components: {
    Paginator,
    PrintHeader
  },
  data(){
    return {
      date_validation_error : '',
      custom_range : false,
      from : '',
      to : '',
      styles: styles
    }
  },
  created: async function(){
    await this.getTransactions()
    await this.getUserData()
  },
  computed:{
    ...mapGetters({
      transactions : 'getTransactions',
      user: 'getUser',
      counts: 'getTotalCounts',
    }),
    totalExpense: function(): string {
      const trans: Transaction[] = this.transactions;
      const requester_company = this.user.company.id;
      let sum = 0.00;
      if(trans !==undefined && requester_company !==undefined ){
        trans.map( function(element: Transaction) {
            if(element){
              if(element.payor){
                  const payor = element.payor as User;
                  const company = payor.company as Company;
                  const company_id = company.id;

                   if(element.amount && company_id === requester_company && parseFloat(element.amount)<0) {
                     sum = sum + parseFloat(element.amount);
                   }
              }
            }
        });
      }
      return Math.abs(sum).toFixed(4);
    },
    getBalance: function(): number {

      if(this.user.company && this.user.company.balance)
        return this.user.company.balance;        

      return 0;
    }
  },
  methods:{
    printDiv:printDiv,

    ...mapActions({
       getTransactions : ActionTypes.FETCH_TRANSACTIONS,
       getUserData: ActionTypes.USER_DATA
    }),
    dateValidation: function(): string {
      if(this.from !== undefined && this.to !== undefined && 
        this.from !=='' && this.to !== '' &&
        Date.parse(this.from) <= Date.parse(this.to)
      ){
        return this.date_validation_error = ""
      }

      return this.date_validation_error =  'Invalid date range';
    },

    trimNumber: function(value: string): string{
        return parseFloat(value !== undefined ? value : '0.0').toFixed(2);
    },

    changePage: async function (pageNo: number) {
      if(this.custom_range){
        if (this.date_validation_error === "")
          await this.getTransactions({start_date:this.from, end_date:this.to, page:pageNo})
      }
      else
        await this.getTransactions({page: pageNo})
    },

    fetchTrans: async function(){
      if(this.custom_range){
         if (this.date_validation_error === "")
           await this.getTransactions({start_date:this.from, end_date:this.to})
      }
      else
        await this.getTransactions()
    },
  }
});
