
import {defineComponent} from 'vue';
import { mapGetters, mapActions } from 'vuex';
import { ActionTypes } from '@/store/modules/order/actions';
import { ActionTypes as AuthActionTypes } from '@/store/modules/auth/actions';
import printDiv from '@/utils/print';
import PrintHeader from '@/components/common-components/PrintHeader.vue'
import {styles1 as styles} from '@/constants/analytics_print'


export default defineComponent({
  name: 'TotalReceivable',
  components: {
    PrintHeader,
  },
  data() {
    return {
      src_img: require('@/assets/DefoultLogoAvatar-01.png'),
      company: 0,
      single_company: "",
    }
  },
  computed: {
    ...mapGetters({
      userdata: 'getUser',
      companies: 'getCompanies',
      singlecompany: 'getSignleCompany',
      total_pay_rec: 'getTotalpayablereceivable',
      logoimg: 'getLogoImg',

    }),
    admin(){
      const allowedRoles = ['SUPER_ADMIN', 'ADMIN'];
      const allowedCompanies = ['PARENT', 'STORE'];
      if(this.userdata != null && allowedRoles.includes(this.userdata.user_type)
        && allowedCompanies.includes(this.userdata.company.company_type) 
      ){
        return true;
      }
      return false;
    },
    
  },
  methods: {
    ...mapActions({
      fetchUser: AuthActionTypes.USER_DATA,
      fetchCompanies: AuthActionTypes.FETCH_COMPANIES,
      fetchTotalpayablereceivable: ActionTypes.TOTAL_PAYABLE_RECEIVABLE,
    }),
    async fetchAnalyticsBtn() {
      await this.fetchTotalpayablereceivable(this.company)
    },
    printDiv(div_id: string, title: string) {
      printDiv(div_id, title, styles);
    },
  },
  async mounted() {
    await this.fetchCompanies();
    await this.fetchUser();
  },
})  

