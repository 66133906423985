
import { defineComponent, onUnmounted } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import { Product, ProductVariant, Unit, Category } from '@/store/models/product';
import { ActionTypes } from '@/store/modules/order/actions';
import ErrorField from '@/components/common-components/ErrorField.vue';

export default defineComponent({
  name: 'AddEditProduct',
  props: ['productId'],
  components: {
    ErrorField,
  },
  data() {
    const productVariants: ProductVariant[] = [];
    return {
      del:require('../../assets/delete_icon.svg'),
      productName_error: "",
      productBar_error: "",
      productVariant_error: "",
      maxlength: {
        name: 50,
        barcode: 48,
        unitName: 20,
        color: 20,
        size: 20,
        description: 250
      },
      product: {
        name: '',
        barcode: '',
        category: '',
        unit: '',
        token: false,
        productVariants: productVariants
      },
      currentProductVariant: {
        color: '',
        size: '',
        price: '',
        sale_price: '',
        description: ''
      },
      VariantPriceValidation: '',
      tablePriceValidation: '',
      updateDeleteLastVarient: ''
    }
  },
  computed: {
    ...mapGetters({
      units: 'getUnits',
      singleProduct: 'getSingleProduct',
      fieldErrors: 'getFieldError',
      categories: 'getCategories',
    })
  },
  methods: {
    // validation function
    productCreationValidation: function () {
      if (this.product.name.length <= 0) {
        this.productName_error = "Name is required"
      }
      else{
        this.productName_error = ""
      }
      if (this.product.barcode.length <= 0) {
        this.productBar_error = "Barcode is required"
      }
      else{
        this.productBar_error = ""
      }
      if (this.product.productVariants.length <= 0) {
        this.productVariant_error = "At least one product variant is required"
      }
      else{
        this.productVariant_error = ""
      }
      if (this.productName_error === "" && 
          this.productBar_error === "" &&
          this.productVariant_error === "")
        {
          return true
        }
      else{
        false
      }
    },
    clearProductVariant: function () {
      this.currentProductVariant.color = '';
      this.currentProductVariant.size = '';
      this.currentProductVariant.price = '';
      this.currentProductVariant.sale_price = '';
      this.currentProductVariant.description = '';
    },

    priceValidation: function (price: string) {
      let errorMessage = null;
      const _price = parseFloat(price);
      if (!_price && isNaN(_price)) {
        errorMessage = 'Price is required';
      } 
      if (!isNaN(_price) &&  _price <= 0) {
        errorMessage = 'price should be greater than zero';
      }
      return errorMessage;
    },

    addProductVariant: function() {
      // validate data
      const validation: any = this.priceValidation(this.currentProductVariant.price);
      if (validation) {
        this.VariantPriceValidation = validation;
        return;
      }
      this.VariantPriceValidation = '';

      const singleProductVariant: ProductVariant = {
        color: this.currentProductVariant.color,
        size: this.currentProductVariant.size,
        price: this.currentProductVariant.price,
        sale_price: this.currentProductVariant.sale_price,
        description: this.currentProductVariant.description
      }

      this.product.productVariants.push(singleProductVariant);
      this.clearProductVariant();
    },

    removeProductVariant: function(index: number) {
      if (this.productId) {
        if (this.product.productVariants.length > 1) {
          const deleteProductVariantID = this.product.productVariants[index].id;
          // Delete Variant
          if (deleteProductVariantID) {
            this.deleteProductVariant(deleteProductVariantID);
            this.updateDeleteLastVarient = '';
            this.product.productVariants.splice(index, 1);
          } else {
            this.product.productVariants.splice(index, 1);
          }
        }
        else if (this.product.productVariants.length == 1) {
          this.updateDeleteLastVarient = 'You cannot delete last product variant.';
        }
      } else {
        this.product.productVariants.splice(index, 1);
      }
    },

    checkTablePrice: function (index: number) {
      const price = this.product.productVariants[index].price;
      this.tablePriceValidation = '';
      if (price) {
        const validation = this.priceValidation(price);
        if (validation) {
          this.tablePriceValidation = validation;
        }
      }
    },

    addUpdateProduct: async function() {
      let productIdNumber = 0;
      if (this.productId) {
        productIdNumber = parseFloat(this.productId);
        if (isNaN(productIdNumber)) return;
      }

      const unproxiedProductVariants = this.product.productVariants.map((item: ProductVariant) => {
        const Variant = {
          price: item.price,
          sale_price: item.sale_price,
          color: item.color,
          description: item.description,
          size: item.size
        } as ProductVariant;
        // To Update
        if (item.id) Variant.id = item.id;
        if (this.productId) Variant.product = productIdNumber;
        return Variant;
      });

      const currentProduct: Product = {
        name: this.product.name,
        bar_code: this.product.barcode,
        token: this.product.token,
        product_variant: unproxiedProductVariants
      };

      currentProduct.unit = null;
      const unitID = parseFloat(this.product.unit);
      if (!isNaN(unitID)) {
        const currentUnit = this.units.find((unitItem: Unit) => unitItem.id === unitID);
        if (currentUnit) {
          currentProduct.unit = {
            id: currentUnit.id,
            name: currentUnit.name
          } as Unit;
        }
      }

      currentProduct.category = null;
      const categoryID = parseFloat(this.product.category);
      if (!isNaN(categoryID)) {
        const currentCategory = this.categories.find((categoryItem: Category) => categoryItem.id === categoryID);
        if (currentCategory) {
          currentProduct.category = {
            id: currentCategory.id,
            name: currentCategory.name
          } as Category;
        }
      }

      // submit to API call action from store
      if (this.productId) {
        currentProduct.id = productIdNumber;
        if(this.productCreationValidation()){
          await this.updateProduct({
            productID: this.productId,
            product: currentProduct
          });
          this.$router.push({name: 'Product'});
        }
      } else {
        if(this.productCreationValidation()){
          await this.createProduct(currentProduct);
          this.$router.push({name: 'Product'});
        }
      }
    },

    loadData: function (product: Product) {
      this.product.name = product.name ? product.name : '';
      this.product.barcode = product.bar_code ? product.bar_code : '';
      this.product.unit = product.unit && typeof product.unit !== 'number' && product.unit.id ? product.unit.id.toString() : '';
      this.product.token = product.token ? product.token : false;
      this.product.productVariants = product.product_variant ? product.product_variant : [];
      this.product.category = product.category && typeof product.category !== 'number' && product.category.id ? product.category.id.toString() : '';
    },

    ...mapActions({
      getUnits: ActionTypes.GET_UNITS,
      getProduct: ActionTypes.GET_SINGLE_PRODUCT,
      createProduct: ActionTypes.CREATE_PRODUCT,
      updateProduct: ActionTypes.UPDATE_PRODUCT,
      deleteProductVariant: ActionTypes.DELETE_PRODUCT_Variant,
      setFieldError: ActionTypes.SET_FIELD_ERROR,
      fetchCategories: ActionTypes.FETCH_CATEGORIES,
    })
  },
  async created () {
    await this.getUnits();
    await this.fetchCategories();
    if (this.productId) {
      await this.getProduct(this.productId);
      console.log(this.singleProduct)
      this.loadData(this.singleProduct);
    }
  },
  async unmounted () {
    await this.setFieldError({});
  }
});
