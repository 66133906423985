
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';
import { getCurrentDate, getCurrentTime } from '@/utils/date_func';

export default defineComponent({
  name: 'PrintHeader',
  data () {
    return{
      src_img: require('@/assets/DefoultLogoAvatar-01.png'),
    }
  },
  computed: {
    ...mapGetters({
      userdata: 'getUser',
      logoimg: 'getLogoImg',
    }),
  },
  methods: {
    currentDate: getCurrentDate,
    currentTime: getCurrentTime,
  }
})
