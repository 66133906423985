import { createVNode as _createVNode, toDisplayString as _toDisplayString, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  id: "header-section",
  style: {"display":"none"}
}
const _hoisted_2 = { class: "company-info" }
const _hoisted_3 = {
  class: "mb-5 pb-5",
  style: {"font-size":"16px"}
}
const _hoisted_4 = { class: "text-center" }
const _hoisted_5 = { class: "text-center" }
const _hoisted_6 = {
  id: "date-section",
  class: "mb-5 pt-5",
  style: {"display":"none"}
}
const _hoisted_7 = { class: "text-center" }
const _hoisted_8 = { class: "text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode("div", _hoisted_1, [
      _createVNode("div", null, [
        _createVNode("img", {
          class: "img-responsive",
          src: _ctx.logoimg ? _ctx.logoimg : _ctx.src_img,
          alt: "Rohi"
        }, null, 8, ["src"])
      ]),
      _createVNode("div", _hoisted_2, [
        _createVNode("p", _hoisted_3, [
          _createVNode("strong", null, _toDisplayString(_ctx.userdata.company.company_name), 1)
        ]),
        _createVNode("p", _hoisted_4, _toDisplayString(_ctx.userdata.company.address), 1),
        _createVNode("p", _hoisted_5, _toDisplayString(_ctx.userdata.company.contact_number), 1)
      ])
    ]),
    _createVNode("div", _hoisted_6, [
      _createVNode("p", _hoisted_7, _toDisplayString(_ctx.currentTime()), 1),
      _createVNode("p", _hoisted_8, _toDisplayString(_ctx.currentDate()), 1)
    ])
  ], 64))
}