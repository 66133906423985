
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import { ActionTypes as OrderActionTypes } from '@/store/modules/order/actions';
import { ActionTypes as AuthActionTypes} from '@/store/modules/auth/actions';
import { Company } from '@/store/models/company';
import axios from 'axios'
import { MutationTypes } from "@/store/modules/order/mutations";


export default defineComponent({
    name: 'Settings',
    data() {
      return {
        tab: 'changeLogo',
        img_file: require('@/assets/rohi_logo.png'),
        selected_file: '',
        f_time: true,
        company: {
          name: '',
          address: '',
          contactNumber: '',
          type: '',
        },
        companyId: "",
        showErrorName: false,
        user_type : "",
        header_color: '#e73b2a',
      }
    },
    computed: {
      comapanyNameValidation: function () {
        let errorMessage = null;
        if (this.company.name.length <= 0 && this.showErrorName == true) {
          errorMessage = "Name is required"
        }
        return errorMessage;
      },
      contactNumberValidation: function () {
      let errorMessage = null;
      if (this.company.contactNumber !== '') {
        const regex = /^((\+92)|(0092))-{0,1}\d{3}-{0,1}\d{7}$|^\d{11}$|^\d{3,5}-{0,1}\d{7}$/;
        if (!regex.test(this.company.contactNumber)) {
          errorMessage = "Phone number is not valid"
        }
      }
      return errorMessage;
    },
    addEditBtn:  function () {
      let disable = true;
      if (this.comapanyNameValidation === null &&
      this.contactNumberValidation === null) {
        disable = false
      }
      return disable;
    },
     ...mapGetters({
        logoimg: 'getLogoImg',
        companyTypes: 'getCompanyTypes',
        userdata: 'getUser',
        
      }),
    },
    methods: {
      addUpdateDepartment: async function () {
        this.showErrorName = true;
        let companyIdNumber = 0;
        if(this.comapanyNameValidation === null){

        if (this.companyId) {
          companyIdNumber = parseFloat(this.companyId);
          if (isNaN(companyIdNumber)) return;
        }

        const company: Company = {
          company_name: this.company.name,
          company_type: this.company.type,
          address: this.company.address,
          contact_number: this.company.contactNumber,
        };

        if (this.companyId) {
          company.id = companyIdNumber;
          await this.updateCompany(company);
        } else {
          alert("Can not update company")
        }
        alert("Company is updated!!")
        }
      },
      
      loadData: function (company: Company) {
        this.company.name = company.company_name ? company.company_name : '';
        this.company.type = company.company_type ? company.company_type : '';
        this.company.address = company.address? company.address : '';
        this.company.contactNumber = company.contact_number? company.contact_number : '';
      },
      ...mapActions({
        updateLogo: OrderActionTypes.UPDATE_LOGO_IMAGE,
        fetchlogo: OrderActionTypes.FETCH_LOGO_IMAGE,
        updateCompany: AuthActionTypes.UPDATE_COMPANY,
        fetchCompany: AuthActionTypes.FETCH_COMPANY,
        fetchTypes: AuthActionTypes.FETCH_TYPES,
        getUserData: AuthActionTypes.USER_DATA,
      }),
      loadImage(event: any) {
        this.img_file = URL.createObjectURL(event.target.files[0])
        this.selected_file = event.target.files[0]
        this.f_time = false
      },
      sendFormData: async function() {
        const formData = new FormData();
        formData.append('image_url', this.selected_file);
        await this.updateLogo(formData)

        await this.fetchlogo()
      },
      changeColor(){
        const cookiedate = new Date();
        cookiedate.setFullYear(cookiedate.getFullYear() + 10);
        const expires = "expires=" + cookiedate.toUTCString();
        document.cookie =
          "HeaderColor=" + this.header_color + ";" + expires + ";path=/";
        this.$store.commit(MutationTypes.SetHeaderColor, this.header_color)
      },
    },
  async beforeMount () {
    await this.fetchlogo();
    await this.fetchTypes();
    await this.getUserData();

    this.companyId = this.userdata['company'].id;
    this.user_type = this.userdata['user_type'];
    if (this.companyId) {
      await this.fetchCompany(this.companyId);
      const company = this.$store.getters.getCompany
      if (Object.keys(company).length === 0 && company.constructor === Object) {
        // Show 404 page on screen
        this.$router.push({name: 'notFound'});
      }
      else {
        this.loadData(company);
      }
    }
  },
})
