
import { OrderItem } from '@/store/models/orderItem';
import { ActionTypes } from '@/store/modules/order/actions';
import { defineComponent } from 'vue';
import {mapActions, mapGetters } from 'vuex';
import offlineStoreService from "@/utils/offline-store/index";
import printDiv from '@/utils/print';
export default defineComponent({
  name: 'OfflineOrderBill',
  data(){
		return{
			src_img: require('@/assets/rohi_logo.png'),
			printDelay: 200,
		}
  },
  methods:{
	
		printBill: function() {
			const styles = `
			<style lang="scss" scoped>	
				@page {
					size: 180mm;
					margin: 0
				}
				@body {
					margin-left: 5%;
				}
				.company-info{
					display: flex;
					flex-wrap: nowrap;
					flex-direction: column;
					align-content: center;
					justify-content: center;
					align-items: center;
					font-size: 12px;
				}

				.maindiv-print {
					padding: 4px;
					max-width: 800px;
				}

				#header-section {
					display: grid;
					grid-template-columns: 1fr 2fr;
					grid-template-rows: 1fr;
					gap: 0.1em 0.1em;
					width:80mm;
				}

				#date-section {
					display: grid;
					grid-template-columns: 1fr 2fr;
					grid-template-rows: 1fr;
					gap: 0.1em 0.1em;
					width:80mm;
				}

				#order-items-section table {
					border-collapse: separate !important;
					width: 80mm;
				}

				#order-items-section th {
					border: 1px solid;
					border-color: black;
					text-align: center;
					padding: 1px;
				}

				#order-items-section td {
					border: none;
					text-align: center;
					padding: 1px;
				}

				#order-items-section tr:nth-child(even) {
					background-color: white;
				}

				#totals-section {
				width: 80mm;
				display: flex;
				justify-content: space-between;
				}

				#totals-section p {
					text-align: right;
				}

				#totals-section td {
					border: none;
					text-align: right;
					padding: 1px;
				}

				#totals-section tr:nth-child(even) {
					background-color: white;
				}

				.mb-5 {
					border-bottom: 2px solid black;
					padding-bottom: 5px;
				}

				.pt-5 {
					padding-top: 5px;
				}

				.img-responsive{
					max-width: 100%;
					height: auto;
				}

				.text-center{
					text-align: center
				}
			</style>
			`;
			printDiv("bill-preview","", styles);
		},

		getCurrentTime(){
			return new Date().toLocaleTimeString();
		},

		getCurrentDate(){
			return new Date().toDateString();
		},

		getItemTotal: function(price: string, quantity: string): string {
			return (parseFloat(price)*parseFloat(quantity)).toFixed(2);
		},

		trimNumber: function(value: string) {
			return parseFloat(value).toFixed(2);

		},


    sleep: function (ms: number) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },

		...mapActions({
			clearOrder: ActionTypes.CHANGE_ORDER_STATUS
		}),
    },
	computed:{
		total: function(): string {
			let sum = 0;
			if(this.order.order_item){
				(this.order.order_item).map((item: any) => {
					sum = sum + parseFloat(item.price?item.price:'0')*parseFloat(item.quantity?item.quantity:'0')
				})
				return sum.toFixed(2);
			}
			else{
				return "";
			}
		},

		change: function(): string {
			if(this.order.amount_received && this.order.total){
				return (parseFloat(this.order.amount_received) - parseFloat(this.order.total)).toFixed(2);
			}
			else{
				return "";
			}
		},
		...mapGetters({
			logoimg: 'getLogoImg',

		})

	},
	props:{
		user: {},
		product_name: {
			default: [""],
		},
    print: {
      default: true,
    },
	order: {
		default: {
			total: "",
			amount_received: "",
			total_discount: "",
			order_item: [],
			payment_service:""
		}
	},
	},
	mounted: function () {
    if (this.print) {
      this.printBill();
    }
	},

});
