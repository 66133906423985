
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import { ActionTypes } from '@/store/modules/auth/actions';

import Header from './common-components/Header.vue';
import ErrorHandler from './common-components/ErrorHandler.vue';
import Loader from './common-components/Loader.vue';

export default defineComponent({
  name: 'App',
  components: { 
    Header,
    ErrorHandler,
    Loader,
  },
  computed: {
    ...mapGetters(['getToken', 'getError', 'getSync'])
  },
  methods: {
    ...mapActions({
      fetchToken: ActionTypes.FETCH_TOEKN
    })
  },
  created() {
    this.fetchToken();
  },
});
