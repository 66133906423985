
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from'vuex';

import { ActionTypes as AuthActionTypes } from '@/store/modules/auth/actions';
import { Transaction } from '@/store/models/transaction';
import Alert from '@/components/common-components/Alert.vue'
import Loader from '@/components/common-components/Loader.vue'
import { User } from '@/store/models/user';
// import { Request } from '@/store/models/request';
import { ActionTypes as OrderActionTypes } from '@/store/modules/order/actions';

export default defineComponent({
  name: 'Expense',
  components: {
    Alert,
    Loader
  },
  data() {
    return {
      radio_check: true,
      payment_type: 'cash',
      numberallowd_error: '',
      amountrequired_error: '',
      descriptionrequired_error: '',
      departmentrequired_error: '',
      payor_payee_same_error: '',
      no_data_table_error: '',
      expenseMethod: 'Received',
      payor_balance: 0,
      payee_balance: 0,
      transaction: {
        payor:-1,
        payee:-1,
        amount:'',
        transaction_id:'',
        description:'',
        p_s: '',
        clear: function() {
          this.payor = -1;
          this.payee = -1;
          this.amount = '';
          this.description = '';
        }
      }, 
      create_expense : false,
      loader: false,
      dept:'',
      table:[{
        method: '',
        payor: -1,
        payee: -1,
        dept_user_id: -1,
        amount:'',
        dept:'',
        p_s:'',
      }],
      clear_table: function(){
        this.table = [{
          method: '',
          payor: -1,
          payee: -1,
          dept_user_id: -1,
          amount:'',
          dept:'',
          p_s:'',
        }]
        this.company = []
      },
      company:[],
    }
  },
  computed: {
    customers: function (): User[] {
      let users =[]
       if (this.userdata.company.company_type === 'STORE' || this.userdata.company.company_type === 'RETIAL') {
        users = this.users.filter((usr: User) => usr && usr.user_type && usr.user_type === 'REGULAR_CUSTOMER');
       
       }
       return users;
    },
    Users: function (): User[] {
      let users =[]
      if(this.expenseMethod === 'Credit' || this.expenseMethod === 'Debit' || this.expenseMethod === 'Received'){
        users = this.users.filter((usr: User) => usr && usr.user_type && usr.user_type != 'REGULAR_CUSTOMER');
      }else{
        users = this.users;
      }
      return users;
    },
    creditUsers: function (): User[] {
      let users = []
      if (this.userdata.company.company_type === 'RETIAL') {
        users = this.users.filter((usr: User) => usr && usr.user_type && usr.user_type === 'REGULAR_CUSTOMER');
      } else if (this.userdata.company.company_type === 'STORE') {
        users = this.users.filter((usr: User) => usr && usr.company
          && typeof usr.company !== 'number' && usr.company.company_type === 'STORE');
        users = [
          ...users,
          ...this.vendors,
        ];
      }
      return users;
    },

    ...mapGetters({
      users: 'getListOfUsers',
      userdata: 'getUser',
      company_balance: 'getUserBalance',
      expense: 'getExpense',
      vendors: 'getListOfVendors',
      companies: 'getInventoryCompanies',
      je_status: 'getJournalEntryStatus',
    })
  },
  methods: {
    ...mapActions({
      fetchUsers: AuthActionTypes.GET_All_USERS,
      fetchUserData: AuthActionTypes.USER_DATA,
      createExpense: AuthActionTypes.CREATE_EXPENSE,
      getVendors: AuthActionTypes.FETCH_VENDORS,
      createRequest: OrderActionTypes.CREATE_REQUEST,
      fetchCompanies: AuthActionTypes.FETCH_ALL_COMPANIES,
      createJournalEntry: AuthActionTypes.CREATE_JOURNAL_ENTRY
    }),
    payorChange: function(){
      let cust_users = [];
      let cust = [];
      cust_users = this.users.filter((usr: User) => usr && usr.user_type && usr.user_type == 'REGULAR_CUSTOMER');
      cust = cust_users.filter((usr: User) => usr && usr.id && usr.id == this.transaction.payor);
      if(cust.length != 0 ){
         this.payor_balance = cust[0].credit;
      }else{
      this.payor_balance = this.company_balance(this.transaction.payor = this.transaction.payor === -1 ? this.userdata.id : this.transaction.payor)
      }
    },
    payeeChange: function(){
      let cust_users = [];
      let cust = [];
      cust_users = this.users.filter((usr: User) => usr && usr.user_type && usr.user_type == 'REGULAR_CUSTOMER');
      cust = cust_users.filter((usr: User) => usr && usr.id && usr.id == this.transaction.payee);
      if(cust.length != 0 ){
         this.payee_balance = cust[0].credit;
      }else{
        this.payee_balance = this.company_balance(this.transaction.payee = this.transaction.payee === -1 ? this.userdata.id : this.transaction.payee);
      }
    },
    table_to_array: function(){
      const table: any = document.querySelector('#journal-entry-table tbody')
      const table_header: any = document.querySelectorAll('#journal-entry-table thead th')
      const data = [];
      for (let i=1; i<table.rows.length; i++) { 
          const tableRow = table.rows[i]; 
          const rowData: any = {}; 
          for (let j=0; j<tableRow.cells.length; j++) {
              const index: any = table_header[j].innerHTML
              rowData[index.toLowerCase().replace(/\s+/g, '')] = tableRow.cells[j].innerHTML
          } 
          data.push(rowData)
      } 
      return data; 
    },
    submitData: async function(){
      const tbldata = this.table_to_array()
      if (this.expenseMethod === 'JE' && tbldata.length != 0) {
        this.no_data_table_error = ''
        this.loader = true
        await this.createJournalEntry(tbldata).finally(() => this.loader = false)
        this.create_expense = this.je_status === 'Created'
        this.clear_table()
        this.transaction.clear()
        await this.fetchUserData();
        await this.fetchUsers();
        await this.getVendors();
      }
      else{
        this.no_data_table_error = 'No data in the table'
      }
      await this.fetchUserData();
    },
    amound_validation: function(){
      if (this.transaction.amount === '') {
        this.amountrequired_error = 'Amount is required';
        return false
      }
      else{
        this.amountrequired_error = ''
        return true
      }
    },
    numbers_allowed_validation: function(){      
      if (this.transaction.amount !== '' && this.transaction.amount !== undefined) {
        const value = parseFloat(this.transaction.amount);
        if (isNaN(value)) 
        {
          this.numberallowd_error = 'Only numbers are allowed';
          return false
        }
        else{
          this.numberallowd_error = ''
          return true
        }
      }
    },
    description_validation: function(){
      if (this.transaction.description === '') {
        this.descriptionrequired_error = 'Description is required';
        return false
      }
      else{
        this.descriptionrequired_error = ''
        return true
      }
    },
    allValidation: function(){
      if(this.amound_validation() && 
        this.numbers_allowed_validation() && 
        this.description_validation())
      {
        return true
      }
      else{
        return false
      }
    },
    je_department_validation: function(){
      console.log(this.company)
      if (this.company.length === 0){
        this.departmentrequired_error = "Department is required"
        return false
      }
      else {
        this.departmentrequired_error = ''
        return true
      }
    },
    je_payor_payee_validation: function(){
      if(this.transaction.payor === this.transaction.payee){
        this.payor_payee_same_error = "Payor and Payee should not be same"
        return false
      }
      else{
        this.payor_payee_same_error = ''
        return true
      }
    },
    journal_entry_validation: function(){
      if(this.je_department_validation() && this.amound_validation() && this.je_payor_payee_validation()){
        return true
      }
      else{
        return false
      }
    },
    addExpense: async function(){
      if(this.allValidation()){
        this.transaction.p_s = this.expenseMethod
        if (this.expenseMethod === 'Received') {
          this.transaction.payee = this.userdata.id;
          this.transaction.payor = this.transaction.payor === -1 ? this.userdata.id : this.transaction.payor;
        } else if(this.expenseMethod === 'Credit') {
          this.transaction.payee = this.transaction.payee === -1 ? this.userdata.id : this.transaction.payee;
          this.transaction.payor = this.userdata.id;
        }else if (this.expenseMethod === 'Debit') {
          this.transaction.payor = this.transaction.payor === -1 ? this.userdata.id : this.transaction.payor;
          this.transaction.payee = this.userdata.id;
          this.transaction.amount = (-parseFloat(this.transaction.amount)).toString();
        } 
        else if (this.expenseMethod === 'Expense') {
          this.transaction.payor = this.userdata.id;
          this.transaction.payee = this.transaction.payee === -1 ? this.userdata.id : this.transaction.payee;
          this.transaction.amount = (-parseFloat(this.transaction.amount)).toString();
        }
        this.loader = true;
        await this.createExpense(this.transaction as Transaction).finally(() => this.loader = false);
        this.create_expense = this.expense && this.expense.id;

        this.transaction.clear();
        await this.fetchUserData();
        await this.fetchUsers();
        await this.getVendors();
      }
    },
    addDept: async function(){
      if(this.journal_entry_validation()){
        this.table.push({method:this.payment_type,
                        payor:this.transaction.payor === -1 ? this.userdata.id : this.transaction.payor,
                        payee:this.transaction.payee === -1 ? this.userdata.id : this.transaction.payee,
                        dept_user_id:this.company[0],
                        amount:this.transaction.amount,
                        dept:this.company[1],
                        p_s: this.expenseMethod})
        this.no_data_table_error = '';
      }
    },
  },
  async beforeMount () {
    await this.fetchUsers();
    await this.getVendors();
    await this.fetchUserData();
    await this.fetchCompanies();
  },

});
