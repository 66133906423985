
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'DateRange',
  emits: ['dateRangeChange'],
  data() {
    const date = new Date();
    const dateStr = `${date.getFullYear()}-${date.getMonth()+1}-${date.getDate()}`;
    return {
      startDate: dateStr,
      endDate: dateStr,
    };
  },
  computed: {
    dateValidation: function(): string | null {
      if(this.startDate !== undefined && this.endDate !== undefined && 
        this.startDate !=='' && this.endDate !== '' &&
        Date.parse(this.startDate) <= Date.parse(this.endDate)
      ){
        return null;
      }
      return 'invalid date range';
    },
  },
  methods: {
    emitRange() {
      if (this.dateValidation === null) {
        return {
          startDate: this.startDate,
          endDate: this.endDate,
        }
      }
      return null
    },
  },
});
