
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';

import { User } from '@/store/models/user';
import { ActionTypes } from '@/store/modules/auth/actions';
import ErrorField from '@/components/common-components/ErrorField.vue';

export default defineComponent({
  name: 'VendorAddEdit',
  props: ['vendorId'],
  components: {
    // ErrorField,
  },
  data () {
    return {
      vendor: {
        contact: '',
        firstName: '',
        lastName: '',
        company: 0
      },
      showErrorContact: false,
      showErrorCompany: false,
    }
  },
  computed: {
    contactNumberValidation: function () {
      let errorMessage = null;
      if (this.vendor.contact.length <= 0 && this.showErrorContact==true) {
        errorMessage = "Number is required"
      }
      return errorMessage;
    },

    addEditBtn:  function () {
      let disable = true;
      if (this.contactNumberValidation === null) {
        disable = false
      }
      return disable;
    },

    companyValidation: function () {
      let errorMessage = null;
      if (this.companies.length <= 0 && this.showErrorCompany==true) {
        errorMessage = "Comapny is required. Add vendor comapany to system"
      }
      return errorMessage;
    },

    ...mapGetters({
      companies: 'getCompanies',
      fieldErrors: 'getAuthFieldError',
    })
  },
  methods: {
    addUpdateVendor: async function () {
      this.showErrorContact = true;
      this.showErrorCompany = true;
      let vendorIdNumber = 0;
      if (this.vendorId) {
        vendorIdNumber = parseFloat(this.vendorId);
        if (isNaN(vendorIdNumber)) return;
      }

      const user: User = {
        first_name: this.vendor.firstName,
        last_name: this.vendor.lastName,
        username: this.vendor.contact,
        contact_number: this.vendor.contact,
        company: this.vendor.company,
        is_active: true
      };

      if (this.vendorId) {
        user.id = vendorIdNumber;
        await this.updateUser(user);
      } else {
        user.user_type = 'VENDOR';
        await this.registerUser(user);
      }
      if (Object.keys(this.fieldErrors).length === 0) {
        this.$router.push({name: 'Vendor'});
      } else {
        window.scrollTo(0,0);
      }
    },

    loadData: function (vendor: User) {
      this.vendor.contact = vendor.contact_number ? vendor.contact_number : '';
      this.vendor.firstName = vendor.first_name ? vendor.first_name : '';
      this.vendor.lastName = vendor.last_name ? vendor.last_name : '';
      this.vendor.company = vendor.company && typeof vendor.company === 'number' ? vendor.company : 0;
    },

    ...mapActions({
      registerUser: ActionTypes.REGISTER_USER,
      updateUser: ActionTypes.UPDATE_USER,
      fetchCompanies: ActionTypes.FETCH_COMPANIES,
      getVendorsList: ActionTypes.FETCH_VENDORS,
      setFieldError: ActionTypes.SET_FIELD_ERROR,
    })
  },
  async beforeMount () {
    await this.fetchCompanies({
      company_type: 'VENDOR',
      search: ''
    });

    if (this.companies && this.companies.length > 0) {
      this.vendor.company = this.companies[0].id;
    }

    if (this.vendorId) {
      await this.getVendorsList('');
      const vendor_id = parseInt(this.vendorId);
      const vendor = isNaN(vendor_id) ? undefined : this.$store.getters.getSignleVendor(vendor_id);
      if (vendor) {
        this.loadData(vendor);
      }
      else {
        // Show 404 page on screen
        this.$router.push({name: 'notFound'});
      }
    }
  },
  async unmounted () {
    await this.setFieldError({});
  },
});
