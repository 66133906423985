
import { defineComponent } from "vue";
import { mapActions, mapGetters } from "vuex";
import { ActionTypes } from "@/store/modules/order/actions";
import { ActionTypes as AuthActionTypes } from "@/store/modules/auth/actions";
import PrintHeader from '@/components/common-components/PrintHeader.vue'
import {styles1 as styles} from '@/constants/analytics_print'
import printDiv from '@/utils/print';

export default defineComponent({
  name: "EndOfDay",
  components: {
    PrintHeader,
  },
  data() {
    const date = new Date();
    const dateStr = `${date.getFullYear()}-${
      date.getMonth() + 1
    }-${date.getDate()}`;
    return {
      src_img: require('@/assets/DefoultLogoAvatar-01.png'),
      startDate: dateStr,
      endDate: dateStr,
      company: 0,
    };
  },
  computed: {
    ...mapGetters({
      salesanalytics: "getSalesanalytics",
      companies: "getInventoryCompanies",
      userdata: "getUser",
      logoimg: 'getLogoImg',
    }),
    admin() {
      const allowedRoles = ["SUPER_ADMIN", "ADMIN"];
      const allowedCompanies = ["PARENT", "STORE"];
      if (
        this.userdata != null &&
        allowedRoles.includes(this.userdata.user_type) &&
        allowedCompanies.includes(this.userdata.company.company_type)
      ) {
        return true;
      }
      return false;
    },
    dateValidation: function (): string | null {
      if (
        this.startDate !== undefined &&
        this.endDate !== undefined &&
        this.startDate !== "" &&
        this.endDate !== "" &&
        Date.parse(this.startDate) <= Date.parse(this.endDate)
      ) {
        return null;
      }
      return "invalid date range";
    },
  },
  methods: {
    ...mapActions({
      fetchSalesanalytics: ActionTypes.FETCH_SALESANALYTICS,
      fetchCompanies: AuthActionTypes.FETCH_ALL_COMPANIES,
      fetchUser: AuthActionTypes.USER_DATA,
    }),
    setRange(range: null | { startDate: Date; endDate: Date }) {
      if (range !== null) {
        this.startDate = range.startDate.toString();
        this.endDate = range.endDate.toString();
      }
    },
    async fetchSalesanalyticsBtn() {
      await this.fetchSalesanalytics({
        start_date: this.startDate,
        end_date: this.endDate,
        company: this.company,
      });
    },
    printDiv(div_id: string, title: string) {
      printDiv(div_id, title, styles);
    },
  },
  async mounted() {
    await this.fetchUser();
    await this.fetchCompanies();
    await this.fetchSalesanalyticsBtn();
    this.company = this.userdata.company.id;
  },
});
