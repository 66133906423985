
import { defineComponent } from "vue";
import Modal from "@/components/common-components/Modal.vue";
import { mapActions, mapGetters } from "vuex";
import { ActionTypes } from "@/store/modules/order/actions";
import { ActionTypes as AuthActionTypes } from "@/store/modules/auth/actions";
import { Order } from "@/store/models/order";
import { Batch } from "@/store/models/batch";
import { OrderItem } from "@/store/models/orderItem";
import { Product, ProductVariant } from "@/store/models/product";
import { User, UserExtra } from "@/store/models/user";
import ErrorField from "@/components/common-components/ErrorField.vue";
import OrderBill from "@/components/sales/OrderBill.vue";
import { Inventory } from "@/store/models/company";
import offlineStoreService from "@/utils/offline-store/index";
import OfflineOrderBill from "../sales/OfflineOrderBill.vue";

export default defineComponent({
  name: "Order",
  components: {
    Modal,
    ErrorField,
    OrderBill,
    OfflineOrderBill,
  },

  data() {
    const today = new Date().toDateString();
    const orderItems: OrderItem[] = [];
    const batches: Batch[] = [];
    const productName: string[] = [];

    return {
      latestOrder: {},
      orderoffline: false,
      submitOrderBtnDisable: false,
      focusedTile: -1,
      focusedID: "",
      cancelModal: false,
      addCustModal: false,
      product: {
        name: "",
        barCode: "",
        quantity: "",
        quantityUpperLimit: 0,
        discount: "",
        batch: "",
        buyPrice: "",
        actualPrice: 0,
      },
      user: {
        userName: "",
        firstName: "",
        lastName: "",
        company: "",
        description: "",
      },
      date: today,
      orderItems: orderItems,
      productId: 0,
      productVariantId: 0,
      productBatchSelect: batches,
      cashReceived: "0",
      totalDiscount: "",
      paymentMethod: "cash",
      errorIndication: true,
      buyer: 2,
      balance: 0,
      customerName: "",
      BarCodeMaxLength: 48,
      ProductNameMaxLength: 60,
      duplicateMessage: "",
      creditPaymentMethod: "cash",
      discountMethod: "amount",
      customersearch: "",
      paymentService: "",
      transactionId: "",
      selectedCutomer: "",
      showCustDropdown: false,
      walkinCustomer: {},
      regularCustomer: {},
      customer: {},
      deduct_balance: false,
      return_order: false,
      print: true,
      productName: productName,
    };
  },
  computed: {
    totalAmount: function (): number {
      let total = this.orderItems
        // eslint-disable-next-line
        .map((item: any) => {
          if (typeof item.totalPrice === "string") {
            return + parseFloat(item.totalPrice);
          }
          return item.totalPrice;
        })
        .reduce((a: number, b: number) => a + b, 0);

      const totalDiscount = parseFloat(this.totalDiscount);
      if (this.discountMethod === "amount") {
        if (!isNaN(totalDiscount) && totalDiscount > 0) {
          total = total - totalDiscount;
        }
      } else if (this.discountMethod === "percentage") {
        if (
          !isNaN(totalDiscount) &&
          totalDiscount > 0 &&
          totalDiscount <= 100
        ) {
          total = total * ((100 - totalDiscount) / 100);
        }
      }
      return total;
    },

    cashReturned: function (): number | null {
      const value = parseFloat(this.cashReceived);
      return isNaN(value) ? null : value - this.totalAmount;
    },

    productNameValidation: function () {
      let errorMessage = null;
      if (!this.errorIndication) {
        // CheckIF product exist on the backend
        if (this.product.name === undefined || this.product.name === "") {
          errorMessage = "Name is required";
        }
      }
      return errorMessage;
    },

    productBarCodeValidation: function () {
      let errorMessage = null;
      if (!this.errorIndication) {
        // CheckIF product exist on the backend
        if (this.product.barCode === undefined || this.product.barCode === "") {
          errorMessage = "Barcode is required";
        }
      }
      return errorMessage;
    },

    productBatchValidation: function () {
      let errorMessage = null;
      if (!this.errorIndication) {
        // CheckIF product exist on the backend
        if (this.product.batch === undefined || this.product.batch === "") {
          errorMessage = "Batch is required";
        }
      }
      return errorMessage;
    },

    productQuantityValidation: function () {
      let errorMessage = null;
      if (!this.errorIndication) {
        // CheckIF product exist on the backend
        if (
          this.product.quantity === undefined ||
          this.product.quantity === ""
        ) {
          errorMessage = "Quantity is required";
        } else {
          const value = parseFloat(this.product.quantity);
          if (isNaN(value)) {
            errorMessage = "Only numbers are allowed";
          } else {
            if (value > this.cummBatchQuantity) {
              errorMessage = "Stock is less than this quantity.";
            }
          }
        }
      }
      return errorMessage;
    },
    filteredBatches: function () {
      let batches: Batch[] = this.productBatchSelect;
      if (!this.return_order) {
        batches = batches.filter(
          (batch: Batch) =>
            batch.inventory_quantity && batch.inventory_quantity > 0
        );
      }
      return batches;
    },
    cummBatchQuantity: function (): number {
      return this.filteredBatches
        .map((batch: Batch) =>
          batch && batch.inventory_quantity ? batch.inventory_quantity : 0
        )
        .reduce((a: number, b: number) => a + b, 0);
    },

    validateDeductBalance: function () {
      let error_message = null;
      if (this.paymentMethod === "credit") {
        if (
          parseFloat(this.cashReceived) < this.totalAmount &&
          this.deduct_balance === false
        ) {
          error_message =
            "Cash Received is less than total so check Deduct Balance!";
        }
      }
      return error_message;
    },
    validateRegularCustomer: function () {
      let error_message = null;
      if (this.paymentMethod === "credit") {
        const regular_cust = this.regularCustomer as User;
        if (regular_cust === undefined || regular_cust.id === undefined) {
          error_message = "select regular customer!";
        }
      }
      return error_message;
    },
    validateReturnOrder: function () {
      let error_message = null;
      if (this.return_order && parseFloat(this.product.quantity) >= 0) {
        error_message = "quantity should be in negative for return order!";
      }

      return error_message;
    },
    returnOrderDisable: function (): boolean {
      // disables the return_order radio after any order item is added.
      return this.orderItems.length > 0;
    },
    selectedBatchQuantity: function (): number {
      let selectedBatchQuantity = 0.0;
      const batchID = parseInt(this.product.batch);
      if (!isNaN(batchID) && batchID > 0 && this.filteredBatches.length > 0) {
        const selectedBatch = this.filteredBatches.find(
          (item: Batch) => item.id === batchID
        );
        selectedBatchQuantity =
          selectedBatch && selectedBatch.inventory_quantity
            ? selectedBatch.inventory_quantity
            : 0;
      }
      return selectedBatchQuantity;
    },

    addProductButton: function (): boolean {
      let disable = true;
      if (
        this.productNameValidation === null &&
        this.productBarCodeValidation === null &&
        this.productBatchValidation === null &&
        this.productQuantityValidation === null &&
        this.productDiscountValidation === null &&
        this.validateReturnOrder === null
      ) {
        disable = false;
      }
      return disable;
    },

    addCustButton: function () {
      if (this.nameValidation === null && this.contactnoValidation === null) {
        return false;
      }
      return true;
    },

    productDiscountValidation: function () {
      let errorMessage = null;
      if (!this.errorIndication) {
        // CheckIF product exist on the backend
        if (
          this.product.discount !== undefined &&
          this.product.discount !== ""
        ) {
          const value = parseFloat(this.product.discount);
          if (isNaN(value)) {
            errorMessage = "Only numbers are allowed";
          } else {
            if (value < 0 || value > 100) {
              errorMessage = "Discount value should be b/w 0 to 100";
            }
          }
        }
      }
      return errorMessage;
    },

    orderTotalDiscountValidation: function () {
      let errorMessage = null;
      if (this.totalDiscount !== undefined && this.totalDiscount !== "") {
        const value = parseFloat(this.totalDiscount);
        if (isNaN(value)) {
          errorMessage = "Only numbers are allowed";
        } else {
          if (this.discountMethod === "amount" && value < 0) {
            errorMessage = "Discount should be greater than zero";
          } else if (
            this.discountMethod === "percentage" &&
            (value < 0 || value > 100)
          ) {
            errorMessage = "Discount value should be b/w 0 to 100";
          }
        }
      }
      return errorMessage;
    },

    orderCashReceivedValidation: function () {
      let errorMessage = null;
      if (
        this.return_order &&
        this.cashReceived &&
        parseFloat(this.cashReceived) !== 0
      ) {
        errorMessage = "Cash Received must be 0 for Return Order.";
      } else if (this.paymentMethod !== "credit") {
        if (this.cashReceived !== undefined && this.cashReceived === "") {
          errorMessage = "Cash is required";
        }
        if (this.cashReceived !== undefined && this.cashReceived !== "") {
          const value = parseFloat(this.cashReceived);
          if (isNaN(value)) {
            errorMessage = "Only numbers are allowed";
          } else {
            if (value < this.totalAmount) {
              errorMessage = "Cash is less than total amount";
            }
          }
        }
      }
      return errorMessage;
    },

    contactnoValidation: function () {
      if (this.user.userName === "") {
        return "contact number is required!";
      }
      return null;
    },

    nameValidation: function () {
      if (this.user.firstName === "" && this.user.lastName === "") {
        return "name can not be empty!";
      }
      return null;
    },

    submitOrderButton: function () {
      let disable = true;
      if (
        this.orderItems.length > 0 &&
        this.orderTotalDiscountValidation === null &&
        this.orderCashReceivedValidation === null &&
        this.validateDeductBalance === null &&
        this.validateRegularCustomer === null &&
        this.submitOrderBtnDisable == false
      ) {
        disable = false;
      }
      return disable;
    },

    getFullName: function (): string {
      const cust: User = this.regularCustomer as User;
      const firstname: string =
        cust.first_name !== undefined ? cust.first_name : "";
      const lastname: string =
        cust.last_name !== undefined ? cust.last_name : "";
      const fullname: string = firstname + " " + lastname;

      return fullname;
    },

    showDeductBalance: function (): boolean {
      return false;
    },

    variantsflatList: function () {
      const variants: { ProductId: any; VariantId: any }[] = [];
      this.productResult.map((item: Product) => {
        if (item) {
          const listofvariants = item.product_variant as ProductVariant[];
          listofvariants.map((variant: ProductVariant) => {
            variants.push({ ProductId: item.id, VariantId: variant.id });
          });
        }
      });
      return variants;
    },

    ...mapGetters({
      productResult: "getProductResults",
      userdata: "getUser",
      orderStatus: "getOrderStatus",
      customers: "getListOfUsers",
      invoiceID: "getInvoiceID",
      field_errors: "getFieldError",
      authFieldErrors: "getAuthFieldError",
      order_response: "getOrder",
      inventory: "getInventory",
    }),
  },
  methods: {
    clearProduct: function () {
      this.productId = 0;
      this.productVariantId = 0;
      this.product.barCode = "";
      this.product.name = "";
      this.product.quantity = "";
      this.product.discount = "";
      this.product.batch = "";
      this.product.quantityUpperLimit = 0;
      const batches: Batch[] = [];
      this.productBatchSelect = batches;
      this.errorIndication = true;
      this.duplicateMessage = "";
      this.product.buyPrice = "";
      this.product.actualPrice = 0;
      (this.$refs.barcode as any)?.focus();
    },

    clearTransaction: function () {
      this.transactionId = "";
      this.paymentService = "BANK";
      this.user.userName = "";
      this.user.firstName = "";
      this.user.lastName = "";
      this.user.company = "";
      this.regularCustomer = {};
    },

    selectProduct: async function (productId: number, VariantId: number) {
      this.duplicateMessage = "";
      const currentProduct = await this.productResult.find(
        (item: Product) => item.id === productId
      );
      const currentVariant = await currentProduct.product_variant.find(
        (item: ProductVariant) => item.id === VariantId
      );
      if (this.sumQuantity(currentVariant) <= 0) return;

      // Check If the product is already in Order Items
      const duplicate = await this.orderItems.find(
        (item: OrderItem) =>
          item.product &&
          item.product === currentProduct &&
          item.productVariant &&
          item.productVariant === currentVariant
      );

      if (duplicate) {
        this.duplicateMessage =
          "The product is already added to the order items.";
        return;
      }

      this.duplicateMessage = "";
      this.productId = productId;
      this.productVariantId = VariantId;
      this.product.barCode = currentProduct.bar_code;
      this.product.name = currentProduct.name;
      this.product.actualPrice = parseFloat(currentVariant.sale_price);
      this.product.quantityUpperLimit = this.sumQuantity(currentVariant);
      this.productBatchSelect = currentVariant.batch
        .filter(
          (batch: Batch) =>
            batch.inventory_quantity && batch.inventory_quantity > 0
        )
        // eslint-disable-next-line
        .sort((x: any, y: any) => +new Date(x.created) - +new Date(y.created));
        this.productBatchSelect = this.productBatchSelect.sort((a, b) => a > b?1:-1);
      const batchId =
        this.filteredBatches.length > 0
          ? (this.filteredBatches[0] as Batch).id
          : undefined;
      this.product.batch = batchId !== undefined ? batchId.toString() : "";
      (
        this.$refs.quantity as HTMLSelectElement & { focus: () => void }
      ).focus();
    },

    selectCustomer: function (customer: User) {
      if (customer.credit === undefined) {
        customer.credit = 0;
      }

      this.regularCustomer = customer;
      this.showCustDropdown = false;
      this.customersearch = customer.contact_number === undefined ? "" :  customer.contact_number;
    },

    addNewCustomer: async function () {
      const companyId = this.userdata.company.id;

      const user_extra: UserExtra = {
        description: this.user.description,
      };

      const user: User = {
        username: this.user.userName,
        contact_number: this.user.userName,
        first_name: this.user.firstName,
        last_name: this.user.lastName,
        company: companyId,
        user_extra: user_extra,
      };

      await this.registerUser(user);

      if (Object.keys(this.authFieldErrors).length === 0) {
        this.addCustModal = false;
        this.getUsersByType({ user_type: "REGULAR_CUSTOMER" });
      }
    },

    addOrderItem: async function () {
      this.errorIndication = false;
      let quantity = + parseFloat(this.product.quantity).toFixed(4);
      quantity = isNaN(quantity) ? 0 : quantity;
      let price = parseFloat(this.product.buyPrice);
      price = isNaN(price) ? 0 : price;

      if (this.product.name === "") return;
      if (this.product.barCode === "") return;
      if (this.product.quantity === "") return;
      if (this.product.batch === "") return;
      if (this.product.buyPrice === "") return;
      if (quantity > this.cummBatchQuantity) return;

      const discount = isNaN(parseFloat(this.product.discount))
        ? 0
        : parseFloat(this.product.discount);
      const batch = parseFloat(this.product.batch);

      if (
        (!this.productId || this.productId === 0) &&
        (!this.productVariantId || this.productVariantId === 0)
      ) {
        this.duplicateMessage =
          "Please select product from product results or add desired product to system";
        return;
      }

      this.duplicateMessage = "";
      const currentProduct = await this.productResult.find(
        (item: Product) => item.id === this.productId
      );
      const currentVariant = await currentProduct.product_variant.find(
        (item: ProductVariant) => item.id === this.productVariantId
      );

      price = currentVariant.sale_price;
      let totalPrice = price * quantity;
      if (this.product.discount && discount > 0 && discount < 100) {
        totalPrice = totalPrice * ((100 - discount) / 100);
      }
      const listOfBatches: {}[] = [];
      if (
        this.selectedBatchQuantity < quantity &&
        this.cummBatchQuantity >= quantity
      ) {
        let totalQuant = quantity;
        let i = 0;
        while (totalQuant > 0 && i < this.filteredBatches.length) {
          const b: Batch = this.filteredBatches[i++];
          const batchQuant = b.quantity ? parseFloat(b.quantity) : 0;
          const unproxiedBatchItem = {
            batchid: b.id ? b.id : 0,
            quantity: 0,
          };
          unproxiedBatchItem.quantity =
            batchQuant < totalQuant ? batchQuant : totalQuant;
          totalQuant -= unproxiedBatchItem.quantity;
          listOfBatches.push(unproxiedBatchItem);
        }
      } else {
        listOfBatches.push({
          batchid: this.product.batch,
          quantity: quantity,
        });
      }
      listOfBatches.map((unproxiedBatchItem: any) => {
        const SingleOrderItem: OrderItem = {
          batch: isNaN(batch)
            ? 0
            : currentVariant.batch
                .map((item: Batch) => {
                  return {
                    id: item.id,
                    quantity: item.quantity,
                  } as Batch;
                })
                .find(
                  (item: Batch) =>
                    item &&
                    item.id &&
                    item.id.toString() == unproxiedBatchItem.batchid
                ),
          product: currentProduct,
          productVariant: currentVariant,
          price: price.toString(),
          quantity: unproxiedBatchItem.quantity.toString(),
          discount: discount.toString(),
          totalPrice: parseFloat(
            (
              unproxiedBatchItem.quantity *
              price *
              ((100 - discount) / 100)
            ).toFixed(4)
          ),
        };
        this.productName.push(this.product.name);
        this.orderItems.push(SingleOrderItem);
      });

      this.clearProduct();
      (this.$refs.barcode as HTMLInputElement & { focus: () => void }).focus();
    },

    submitOrder: async function (print: boolean) {
      this.submitOrderBtnDisable = true;
      if (this.orderItems.length < 0) return;
      if (this.cashReceived === "") return;
      this.print = print;

      const unproxiedOrderItem = await this.orderItems.map(
        (singleOrderItem: OrderItem) => {
          return {
            batch:
              singleOrderItem.batch &&
              typeof singleOrderItem.batch !== "number" &&
              singleOrderItem.batch.id
                ? singleOrderItem.batch.id
                : 0,
            price: singleOrderItem.price?.toString(),
            discount: singleOrderItem.discount?.toString(),
            quantity: singleOrderItem.quantity?.toString(),
          } as OrderItem;
        }
      );

      const cash = parseFloat(this.cashReceived);
      const discount = parseFloat(this.totalDiscount);
      const buyer: User =
        this.paymentMethod === "credit"
          ? this.regularCustomer
          : this.walkinCustomer;
      this.customer = buyer;
      const singleOrder: Order = {
        order_item: unproxiedOrderItem,
        buyer: buyer && buyer.id ? buyer.id : this.userdata.id,
        seller: this.userdata.id,
        total_discount: isNaN(discount) ? "0" : discount.toString(),
        total: parseFloat(this.totalAmount.toString()).toFixed(4),
        cash_payment:
          this.paymentMethod === "cash"
            ? true
            : this.creditPaymentMethod === "cash",
        amount_received: isNaN(cash) ? "0" : cash.toString(),
        amount_discount: this.discountMethod === "amount" ? true : false,
        payment_service:
          this.paymentMethod === "cash" ? "BANK" : this.paymentService,
        transaction_id: this.transactionId,
        invoice_id: this.invoiceID,
        deduct_balance: this.deduct_balance,
      };

      if (this.return_order) {
        singleOrder.status = "RETURNED";
      }
      if(await offlineStoreService.isInternetConnectionWorking() === false){
        this.orderoffline = true;
        this.latestOrder = singleOrder;
      }
      await this.createOrder(singleOrder);
      this.submitOrderBtnDisable = false;
      await this.getUsersByType({ user_type: "REGULAR_CUSTOMER" });
    },

    changeQuantity: function (index: number) {
      const currentVariant = this.orderItems[index].productVariant;
      if (currentVariant !== undefined) {
        const currentOrderItemQuantity = this.orderItems[index].quantity;
        const currentOrderItemPrice = this.orderItems[index].price;
        const currentDiscount = this.orderItems[index].discount;
        const currentBatch = this.orderItems[index].batch;

        if (
          currentOrderItemQuantity !== undefined &&
          currentOrderItemPrice !== undefined &&
          currentBatch !== undefined &&
          typeof currentBatch !== "number"
        ) {
          const quantity = parseFloat(currentOrderItemQuantity);
          const price = parseFloat(currentOrderItemPrice);
          const discount = currentDiscount ? parseFloat(currentDiscount) : 0;
          const upperLimit = currentBatch.inventory_quantity
            ? currentBatch.inventory_quantity
            : 0;

          if (isNaN(price)) return;
          if (isNaN(quantity)) return;
          if (quantity > upperLimit) return;
          let total = quantity * price;
          if (!((isNaN(discount) && discount <= 0) || discount > 100)) {
            total = total * ((100 - discount) / 100);
          }
          this.orderItems[index].totalPrice = parseInt(total.toFixed(0));
        }
      }
    },

    changeDiscount: function (index: number) {
      const currentOrderItemDiscount = this.orderItems[index].discount;
      const currentOrderItemPrice = this.orderItems[index].price;
      const currentOrderItemQuantity = this.orderItems[index].quantity;
      if (
        currentOrderItemDiscount !== undefined &&
        currentOrderItemPrice !== undefined &&
        currentOrderItemQuantity !== undefined
      ) {
        const quantity = parseFloat(currentOrderItemQuantity);
        const price = parseFloat(currentOrderItemPrice);
        const discount = parseFloat(currentOrderItemDiscount);

        if ((isNaN(discount) && discount <= 0) || discount > 100) return;
        this.orderItems[index].totalPrice = parseInt(
          (price * quantity * ((100 - discount) / 100)).toFixed(4)
        );
      }
    },

    changePrice: function (index: number) {
      const currentOrderItemPrice = this.orderItems[index].price;
      const totalPrice = this.orderItems[index].totalPrice;
      if (totalPrice !== undefined && currentOrderItemPrice !== undefined) {
        const price = parseFloat(currentOrderItemPrice);

        if (isNaN(price)) return;
        if (totalPrice < 0) return;

        this.orderItems[index].quantity = (totalPrice / price)
          .toFixed(4)
          .toString();
        this.orderItems[index].discount = (0.0).toString();
      }
    },

    removeItem: function (index: number) {
      this.orderItems.splice(index, 1);
    },

    searchCustomer: function (event: Event) {
      if (event) event.preventDefault();

      this.getUsersByType({
        search: this.customersearch,
        user_type: "REGULAR_CUSTOMER",
      });
    },

    searchByName: async function (event: Event) {
      if (event) {
        event.preventDefault();
      }
      await this.searchProductByName(this.product.name);
    },

    checkkey: function (event: KeyboardEvent) {
      const variantslist = this.variantsflatList;
      if (event.key === "ArrowDown") {
        this.focusedTile++;
        if (variantslist.length <= this.focusedTile) {
          this.focusedTile = 0;
        }
        if (variantslist.length > 0) {
          const focused = variantslist[this.focusedTile];
          const refid = focused.ProductId + "_" + focused.VariantId;
          this.focusedID = refid;
          this.fixScrolling();
          (
            this.$refs[refid] as HTMLSelectElement & { focus: () => void }
          )?.focus();
        }
      } else if (event.key === "Enter") {
        if (this.focusedTile < variantslist.length) {
          const focused = variantslist[this.focusedTile];
          this.selectProduct(focused.ProductId, focused.VariantId);
        }
      } else if (event.key === "ArrowUp") {
        this.focusedTile--;
        if (0 > this.focusedTile) {
          this.focusedTile = 0;
        }
        if (variantslist.length > 0) {
          const focused = variantslist[this.focusedTile];
          const refid = focused.ProductId + "_" + focused.VariantId;
          this.focusedID = refid;
          this.fixScrolling();
          (
            this.$refs[refid] as HTMLSelectElement & { focus: () => void }
          )?.focus();
        }
      }
    },
    shiftfocusTo: function (event: KeyboardEvent, to: string, key = "Enter") {
      if (event.key === key) {
        (this.$refs[to] as HTMLSelectElement & { focus: () => void })?.focus();
      }
    },
    fixScrolling() {
      const liH = (this.$refs.options as HTMLElement).clientHeight;
      (this.$refs.scrollContainer as any).scrollTop = liH * this.focusedTile;
    },
    searchByBarcode: async function (event: Event) {
      await this.searchProductByBarcode(this.product.barCode);

      // if(this.productResult.length === 1){
      // const searchedProduct: Product = this.productResult[0];
      // if(searchedProduct.id && searchedProduct.product_variant && searchedProduct.product_variant.length>0 && searchedProduct.product_variant[0].id)
      //     await this.selectProduct(searchedProduct.id, searchedProduct.product_variant[0].id);
      // }
    },

    sumQuantity: function (item: ProductVariant): number {
      let sum = 0;
      if (item.batch !== undefined && typeof item.batch !== "number") {
        item.batch
          .filter(
            (batch: Batch) =>
              batch.inventory_quantity && batch.inventory_quantity > 0
          )
          .forEach((batch: Batch) => {
            if (batch.inventory_quantity !== undefined) {
              sum = sum + batch.inventory_quantity;
            }
          });
      }
      return sum;
    },

    handleOrderStatus: async function () {
      this.changeOrderStatus("");
      this.clearProduct();
      await this.searchProductByBarcode(""); //this statement will clear the search results from action
      this.orderItems = [];
      this.cashReceived = "";
      this.totalDiscount = "";
      this.paymentMethod = "cash";
      this.cancelModal = false;
      await this.fetchInvoiceID();
      if(await offlineStoreService.isInternetConnectionWorking() === false){
        this.orderoffline = false;
      }
    },

    changeProductPrice: function () {
      let quantity = 0;
      if (this.product.actualPrice > 0 && this.product.buyPrice) {
        const price = parseFloat(this.product.buyPrice);

        if (!isNaN(price)) {
          quantity = price / this.product.actualPrice;
        }
      }
      this.product.quantity = quantity.toFixed(4).toString();
    },

    changeProductQuantity: function () {
      let buyPrice = 0;
      if (this.product.actualPrice > 0 && this.product.quantity) {
        const quantity = parseFloat(this.product.quantity);

        if (!isNaN(quantity)) {
          buyPrice = quantity * this.product.actualPrice;
        }
      }
      this.product.buyPrice = buyPrice.toFixed(4).toString();
    },

    trimQuantity: function (quan: string): string {
      return parseFloat(quan !== undefined ? quan : "0.0").toFixed(4);
    },


    ...mapActions({
      searchProductByName: ActionTypes.SEARCH_PRODUCT_BY_NAME,
      searchProductByBarcode: ActionTypes.SEARCH_PRODUCT_BY_BARCODE,
      createOrder: ActionTypes.CREATE_ORDER,
      changeOrderStatus: ActionTypes.CHANGE_ORDER_STATUS,
      getUsers: AuthActionTypes.GET_USERS,
      getUsersByType: AuthActionTypes.GET_CUSTOMER_USERS,
      registerUser: AuthActionTypes.REGISTER_USER,
      fetchInvoiceID: ActionTypes.FETCH_INVOICE_ID,
      setFieldError: ActionTypes.SET_FIELD_ERROR,
      fetchOrder: ActionTypes.FETCH_ORDER,
      fetchInventory: ActionTypes.FETCH_INVENTORY,
    }),
  },
  async beforeMount() {
    await this.fetchInvoiceID();
    await this.getUsersByType({ user_type: "WALK_IN_CUSTOMER" });
    this.walkinCustomer = this.customers.find(
      (item: User) =>
        item.username &&
        item.username === `WALK_IN_CUSTOMER_${this.userdata.company.id}`
    );
    await this.getUsersByType({ user_type: "REGULAR_CUSTOMER" });
    if(await offlineStoreService.isInternetConnectionWorking()){
      await offlineStoreService.Order();
      await offlineStoreService.initialize();
    }
  },
  async unmounted() {
    await this.setFieldError({});
  },
});
