
import {defineComponent} from 'vue';
import { mapActions, mapGetters } from 'vuex';

import { ActionTypes } from '@/store/modules/order/actions';
import { ActionTypes as AuthActionTypes } from '@/store/modules/auth/actions';
import { Category } from '@/store/models/product'
import Paginator from '@/components/common-components/Paginator.vue';
import printDiv from '@/utils/print';
import PrintHeader from '@/components/common-components/PrintHeader.vue'
import {styles1 as styles} from '@/constants/analytics_print'

export default defineComponent({
  name: 'OperatorSalesDetail',
  components: {
    Paginator,
    PrintHeader,
  },
  data() {
    return {
      src_img: require('@/assets/DefoultLogoAvatar-01.png'),
      company: "",
      category:"",
      checkall: false,
      checkedValue: -1,
    };
  },
  computed: {
    ...mapGetters({
      inventory: 'getInventory',
      companies: 'getInventoryCompanies',
      userdata: 'getUser',
      categories: 'getCategories',
      counts: "getTotalCountsOrderModule",
      logoimg: 'getLogoImg',
    }),
    admin(){
      const allowedRoles = ['SUPER_ADMIN', 'ADMIN'];
      const allowedCompanies = ['PARENT', 'STORE'];
      if(this.userdata != null && allowedRoles.includes(this.userdata.user_type)
        && allowedCompanies.includes(this.userdata.company.company_type) 
      ){
        return true;
      }
      return false;
    },
  },
  methods: {
    ...mapActions({
      getProducts: ActionTypes.PRODUCT_QUANTITY,
      fetchCompanies: AuthActionTypes.FETCH_ALL_COMPANIES,
      fetchUser: AuthActionTypes.USER_DATA,
      fetchCategories: ActionTypes.FETCH_CATEGORIES,
    }),
    onCheckChange: async function(e: any){
      this.category = e.target.value
      this.checkall = false
      this.checkedValue = e.target.value
    },

    onCheckAll: async function(e: any){
      this.category = ""
      this.checkall = true
      this.checkedValue = e.target.value
    },
    async fetchAnalyticsBtn() {
      await this.getProducts({
         company: this.company,
         category: this.category
      });
    },
    changePage: async function (pageNo: number) {
      await this.getProducts({
        company: this.company,
        category: this.category,
        page: pageNo,
      });
    },
    showcategoryName: function(categories: Category[], id: number) {
      const name=categories.find(function(data) {
          if(data.id === id){
              return data.name
          }
      });
      if(name){
        return name.name
      }
      else{
        return "NA"
      }
    },
    printDiv(div_id: string, title: string) {
      printDiv(div_id, title, styles);
    },
  },
  async mounted() {
    await this.fetchUser();
    await this.fetchCompanies();
    await this.fetchAnalyticsBtn();
    await this.fetchCategories();
    this.company = this.userdata.company.id;
  },
})
