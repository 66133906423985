
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';

import Modal from '@/components/common-components/Modal.vue';
import Paginator from '@/components/common-components/Paginator.vue';
import { ActionTypes } from '@/store/modules/auth/actions';
import { Company } from '@/store/models/company';

export default defineComponent({
  name: 'Department',
  components: {
    Modal,
    Paginator,
  },
  data() {
    return {
      deleteCompanyModal: false,
      companyType: '',
      search: '',
      company: {
        id: 0,
        name: ''
      }
    }
  },
  computed: {
    ...mapGetters({
      companies: 'getCompanies',
      companyTypes: 'getCompanyTypes',
      counts: 'getTotalCounts',
    })
  },
  // define methods under the `methods` object
  methods: {
    clearCompany: function () {
      this.company.id = 0;
      this.company.name = '';
    },

    openDeleteCompanyModal: function(company: Company) {
      this.company.id = company.id ? company.id : 0;
      this.company.name = company.company_name ? company.company_name : '';
      this.deleteCompanyModal = true;
    },

    closeDeleteCompanyModal: function() {
      this.deleteCompanyModal = false;
      this.clearCompany();
    },

    deletecompany: async function() {
      await this.deleteCompany(this.company.id);
      await this.fetchCompanies({
      company_type: '',
      search: ''
    });
      this.clearCompany();
      this.deleteCompanyModal = false;
    },

    onChangeCompanyType: async function () {
      await this.fetchCompanies({
        company_type: this.companyType,
        search: this.search
      });
    },

    onSearchCompany: async function (event: Event) {
      if (event) {
        event.preventDefault()
      }
      await this.fetchCompanies({
        company_type: this.companyType,
        search: this.search
      });
    },

    changePage: async function (pageNo: number) {
      await this.fetchCompanies({
        company_type: this.companyType,
        search: this.search,
        page: pageNo
      });
    },

    ...mapActions({
      fetchCompanies: ActionTypes.FETCH_COMPANIES,
      deleteCompany: ActionTypes.DELETE_COMPANY,
      fetchTypes: ActionTypes.FETCH_TYPES,
    })
  },
  async beforeMount () {
    await this.fetchTypes();
    await this.fetchCompanies({
      company_type: '',
      search: ''
    });
  }
});
