
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';

import Paginator from '@/components/common-components/Paginator.vue';
import OrderBill from '@/components/sales/OrderBill.vue';
import Modal from '@/components/common-components/Modal.vue';

import { ActionTypes } from '@/store/modules/order/actions';
import { isManager } from '@/utils/permission_utils';

import { getCurrentDate, getCurrentTime } from '@/utils/date_func';

export default defineComponent({
  name: 'OrdersList',
  components: {
    Paginator,
    OrderBill,
    Modal
  },
  data () {
    return {
      search: '',
      orderStatus: '',
      orderDate: '',
      paymentMethod: '',
      printOrder: false,
      orderid: '',
    }
  },
  computed: {
    ...mapGetters({
      orders: 'getListOfOrders',
      statuses: 'getOrderStatuses',
      counts: 'getTotalCountsOrderModule',
      user: 'getUser',
      order_response: 'getOrder',
    })
  },
  methods: {

    getCurrentDate: getCurrentDate,

    getCurrentTime: getCurrentTime,

    clearFilters: async function () {
      this.paymentMethod = '';
      this.orderStatus = '';
      this.orderDate = '';

      const unproxied_options = {
        invoice_id__contains: this.search,
        buyer__company: '',
        seller__company: '',
      }

      await this.fetchOrders(this.getOptions(unproxied_options));
    },

    getOptions: function(options: any){
        if(isManager()){
          options.buyer__company=this.user.company.id;
          options.seller__company=this.user.company.id;
        }
        return options;
    },
    onChangeFilters: async function () {
      const unproxied_options = {
        buyer__company: '',
        seller__company: '',
        invoice_id__contains: this.search,
        status: this.orderStatus,
        cash: this.paymentMethod,
        created__date: this.orderDate
      }

      await this.fetchOrders(this.getOptions(unproxied_options));
    },

    searchOrders: function (event: Event) {
      if (event) {
        event.preventDefault()
      }
      const unproxied_options = {
        buyer__company: '',
        seller__company: '',
        invoice_id__contains: this.search,
        status: this.orderStatus,
        cash: this.paymentMethod,
        created__date: this.orderDate
      }

      this.fetchOrders(this.getOptions(unproxied_options))
    },

    changePage: async function (pageNo: number) {
      const unproxied_options = {
        invoice_id__contains: this.search,
        status: this.orderStatus,
        cash: this.paymentMethod,
        created__date: this.orderDate,
        page: pageNo,
        buyer__company:'',
        seller__company:''
      }

      await this.fetchOrders(this.getOptions(unproxied_options));
    },
    closeReprintDialog: function(){
        this.changeOrderStatus('');
        this.printOrder=false;
    },
    printOrderBill: async function(orderid: any){
      console.log(orderid);
      await this.fetchOrder(orderid);
      this.printOrder=true;
    },
    trimDecimalPlaces: function (value: string) {
      return parseFloat(value !== undefined ? value : '0.0').toFixed(4);
    },

    ...mapActions({
      fetchOrders: ActionTypes.FETCH_ORDERS,
      fetchOrderStatuses: ActionTypes.FETCH_ORDER_STATUSES,
      fetchOrder: ActionTypes.FETCH_ORDER,
      changeOrderStatus: ActionTypes.CHANGE_ORDER_STATUS
    })
  },
  async beforeMount () {
    if (this.$route.query.invoiceId || this.$route.query.date) {
      this.orderDate = this.$route.query.date ? this.$route.query.date as string : '';
      this.search = this.$route.query.invoiceId ? this.$route.query.invoiceId as string : '';
        await this.onChangeFilters();
    } else {
        await this.fetchOrders(this.getOptions({}));
    }
    await this.fetchOrderStatuses();
  }
});
