
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'ConnectionError',
    methods: {
        reload: function () {
            this.$router.push({name: 'Order'});
        }
    }
})
