
import {defineComponent} from 'vue';
import { mapActions, mapGetters } from 'vuex';
import { ActionTypes } from '@/store/modules/order/actions';
import { ActionTypes as AuthActionTypes } from '@/store/modules/auth/actions';
import printDiv from '@/utils/print';
import PrintHeader from '@/components/common-components/PrintHeader.vue'
import {styles1 as styles} from '@/constants/analytics_print'

export default defineComponent({
  name: 'TotalPayable',
  components: {
    PrintHeader,
  },
  data() {
    return {
      src_img: require('@/assets/DefoultLogoAvatar-01.png'),
      single_company: "",
      company: 0,
    };
  },
  computed: {
    ...mapGetters({
      total_pay_rec: 'getTotalpayablereceivable',
      companies: 'getCompanies',
      userdata: 'getUser',
      singlecompany: 'getSignleCompany',
      logoimg: 'getLogoImg',
    }),
    admin(){
      const allowedRoles = ['SUPER_ADMIN', 'ADMIN'];
      const allowedCompanies = ['PARENT', 'STORE'];
      if(this.userdata != null && allowedRoles.includes(this.userdata.user_type)
        && allowedCompanies.includes(this.userdata.company.company_type) 
      ){
        return true;
      }
      return false;
    },
  },
  methods: {
    ...mapActions({
      fetchTotalpayablereceivable: ActionTypes.TOTAL_PAYABLE_RECEIVABLE,
      fetchCompanies: AuthActionTypes.FETCH_COMPANIES,
      fetchUser: AuthActionTypes.USER_DATA,
    }),
   
    async fetchTotalPayableBtn() {
       await this.fetchTotalpayablereceivable(this.company);
    },
    getCurrentTime(){
			return new Date().toLocaleTimeString();
		},
		getCurrentDate(){
			const current = new Date()
      return current.toLocaleDateString()
		},
    // async fetchSalesanalyticsBtn() {
    //   await this.fetchSalesanalytics({
    //     start_date: this.startDate,
    //     end_date: this.endDate,
    //     company: this.company,
    //   });
    //     },
    printDiv(div_id: string, title: string) {
      printDiv(div_id, title, styles);
    },
  },
  async mounted() {
    await this.fetchUser();
    await this.fetchCompanies();
  },
})
