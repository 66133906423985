
import { defineComponent, computed, onBeforeMount } from 'vue';
import { mapActions, mapGetters, useStore } from 'vuex';
import { ActionTypes } from '@/store/modules/order/actions';
import { ActionTypes as AuthActionTypes } from '@/store/modules/auth/actions';
import { BarChart } from 'vue-chart-3';
import { Chart, registerables } from "chart.js";
import PrintHeader from '@/components/common-components/PrintHeader.vue'
import {styles2 as styles} from '@/constants/analytics_print'
import printDiv from '@/utils/print';

Chart.register(...registerables);

export default defineComponent({
  name: 'ComparisonExpenseSales',
  components: {
      BarChart,
      PrintHeader,
  },
  data() {
    return {
      src_img: require('@/assets/DefoultLogoAvatar-01.png'),
      company: 0,
      chartData: {},
      options: {
        responsive: true,
        scales: {
          y: {
            title: {
              display: true,
              text: 'Amount'
            }
          }
        },  
        plugins: {
          legend: {
            position: 'top',
          },
          title: {
            display: true,
            text: 'Sales-Expense Comparison Chart',
          },
        },      
      },
      
    };
  },
  computed: {
    ...mapGetters({
      companies: 'getInventoryCompanies',
      userdata: 'getUser',
      expensesales: 'getExpenseSales',
      logoimg: 'getLogoImg',
    }),
  },
  methods: { 
    ...mapActions({
      fetchAnalytics: ActionTypes.FETCH_ANALYTICS,
      fetchCompanies: AuthActionTypes.FETCH_ALL_COMPANIES,
      fetchexpensesales: ActionTypes.FETCH_EXPENSE_SALES, 
      fetchUser: AuthActionTypes.USER_DATA,
    }),
    admin(){
      const allowedRoles = ['SUPER_ADMIN', 'ADMIN'];
      const allowedCompanies = ['PARENT', 'STORE'];
      if(this.userdata != null && allowedRoles.includes(this.userdata.user_type)
        && allowedCompanies.includes(this.userdata.company.company_type) 
      ){
        return true;
      }
      return false;
    },
    fillChartData(){
      this.chartData = {
        labels: [this.expensesales['company_name']],
        datasets: [
          {
            label: "Sale",
            data: [this.expensesales['total_sales']],
            backgroundColor: ['#edbf33', '#b33dc6', '#ef9b20', '#ea5545', '#0bb4ff'],
            borderColor: ['#edbf33', '#b33dc6', '#ef9b20', '#ea5545', '#0bb4ff'],
          },
          {
            label: "Expense",
            data: [-(this.expensesales['total_expense'])],
            backgroundColor: ['#0bb4ff', '#50e991', '#e6d800', '#9b19f5', '#e60049'],
            borderColor: ['#0bb4ff','#50e991', '#e6d800', '#9b19f5', '#e60049'],
          },
        ],
      };
    },
    async fetchAnalyticsComparisonBtn() {
      await this.fetchexpensesales(this.company);
      this.fillChartData()
    },
    printDiv(div_id: string, title: string) {
      const canvas = document.getElementById('bar-chart') as HTMLCanvasElement;
      const img = canvas.toDataURL('image/png')
      const graph_div = document.getElementById('bar-chart') as HTMLBodyElement
      const graph_img = document.getElementById('graph-img') as HTMLImageElement
      graph_img.src = img
      printDiv(div_id, title, styles);
    },
  },
  async mounted() {
    await this.fetchUser();
    await this.fetchCompanies();
    this.company = this.userdata.company.id;
    await this.fetchexpensesales(this.company);
    this.fillChartData()
  }
})
