
import { defineComponent } from 'vue'
import {ActionTypes} from '@/store/modules/auth/actions'
import {mapActions, mapGetters} from 'vuex';

import Paginator from './Paginator.vue';


export default defineComponent({
  components: {
    Paginator,
  },
  data() {
    return {
      notificationsCount: 10,
    }
  },
  computed:{
    ...mapGetters({
      user: 'getUser',
      messages: 'getNotifications',
    })
  },
  methods:{
    changePage: async function (pageNo: number) {
      // TODO: Call notifiactions api for next page
      console.log(pageNo)
    },

    ...mapActions({
      userdata: ActionTypes.USER_DATA
    })
  },
  beforeMount: async function(){
    await this.userdata();
    // this.$socket.emit('client_info', {id: this.user.id, company: this.user.company.id});
  }
})
