
import { defineComponent } from "vue";
import { mapActions, mapGetters } from "vuex";

import Loader from "@/components/common-components/Loader.vue";
import Alert from "@/components/common-components/Alert.vue";
import { ActionTypes as AuthActionTypes } from "@/store/modules/auth/actions";
import { ActionTypes as OrderActionTypes } from "@/store/modules/order/actions";
import { Request } from "@/store/models/request";

export default defineComponent({
  name: "Request",
  components: {
    Loader,
    Alert,
  },
  
  data() {
    return {
      loader: false,
      company: 0,
      requestee: 0,
      requestType: "SPECIAL_ORDER",
      delivery: "",
      description: "",
      created: "",

      showErrorCompany: false,
      showErrorUser: false,
      showErrorDelivery: false,
      showErrorDescription: false,
    };
  },
  computed: {
    companyValidation: function () {
      let errorMessage = null;
      if (this.companies.length <= 0 && this.showErrorCompany == true) {
        errorMessage = "Comapny is required. Add store to system";
      }
      return errorMessage;
    },

    requesteeValidation: function () {
      let errorMessage = null;
      if (this.users.length <= 0 && this.showErrorUser == true) {
        errorMessage = "Requestee is required. Add store's user to system.";
      }
      return errorMessage;
    },

    deliveryValidation: function () {
      let errorMessage = null;
      if (this.delivery === "" && this.showErrorDelivery == true) {
        errorMessage = "Delivery is required";
      }
      return errorMessage;
    },

    descriptionValidation: function () {
      let errorMessage = null;
      if (this.description === "" && this.showErrorDescription == true) {
        errorMessage = "Description is required";
      }
      return errorMessage;
    },

    addBtn: function () {
      let disable = true;
      if (
        this.companyValidation === null &&
        this.requesteeValidation === null &&
        this.deliveryValidation === null &&
        this.descriptionValidation === null
      ) {
        disable = false;
      }
      return disable;
    },

    ...mapGetters({
      companies: "getCompanies",
      requestTypes: "getRequestTypes",
      userData: "getUser",
      users: "getListOfUsers",
      requestSent: "getRequest",
    }),
  },
  methods: {
    
    onChangeCompany: async function () {
      if (this.company > 0) {
        await this.getUsers({
          company: this.company,
        });
        if (this.users && this.users.length > 0) {
          this.requestee = this.users[0].id;
        }
      }
    },

    addRequestbtn: async function () {
      
      this.showErrorCompany= true
      this.showErrorUser= true
      this.showErrorDelivery=true
      this.showErrorDescription=true
      if(this.requesteeValidation == null && this.companyValidation==null &&
        this.deliveryValidation == null &&
        this.descriptionValidation == null)
      {
        this.loader = true;
      const request: Request = {
        sender: this.userData.id,
        receiver: this.requestee,
        expected_delivery_date: this.delivery,
        description: this.description,
        request_type: this.requestType,
      };

      await this.createRequest(request);

      this.delivery = "";
      this.description = "";
      this.requestType = "SPECIAL_ORDER";
      this.loader = false;

      this.created = "";
      if (this.requestSent && this.requestSent.id) {
        this.created = `Request is sent. Request id is ${this.requestSent.id}`;
      }
      }
    },

    ...mapActions({
      fetchCompanies: AuthActionTypes.FETCH_COMPANIES,
      fetchTypes: AuthActionTypes.FETCH_TYPES,
      createRequest: OrderActionTypes.CREATE_REQUEST,
      getUserData: AuthActionTypes.USER_DATA,
      getUsers: AuthActionTypes.GET_USERS,
    }),
  },
  async beforeMount() {
    await this.getUserData();
    await this.fetchTypes();
    await this.fetchCompanies({
      company_type: "STORE",
      search: "",
    });
    if (this.companies && this.companies.length > 0) {
      this.company = this.companies[0].id;
      await this.onChangeCompany();
    }
  },
});
