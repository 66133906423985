
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import { ActionTypes } from '@/store/modules/auth/actions';
import { ActionTypes as OrderActionTypes } from '@/store/modules/order/actions';
import offlineStoreService from '@/utils/offline-store';
import { Notification } from '@/store/models/notification';
import { MutationTypes } from "@/store/modules/order/mutations";


export default defineComponent({
  name: 'Header',
  data () {
    return{
      logo: require('../../assets/login-top-logo.svg'),
      src_img: require('../../assets/DefoultLogoAvatar-01.png'),
      toggle:false,
      show:false,
      animated:false,
      login_user_png: require('../../assets/DefaultProfileAvatar-01.png'),
      order: require('../../assets/new-order.svg'),
      inventory: require('../../assets/inventory.svg'),
      users: require('../../assets/users.svg'),
      request:require('../../assets/request.svg'),
      analytics: require('../../assets/analytics.svg'),
      products: require('../../assets/products.svg'),
      vendor: require('../../assets/Vendors.svg'),
      batches: require('../../assets/batches.svg'),
      department: require('../../assets/department.svg'),
      settings:require('../../assets/request.svg'),
      responses:require('../../assets/response.svg'),
      summary:require('../../assets/summary.svg'),
      finance:require('../../assets/finance.svg'),
      log:require('../../assets/logout-arrow.svg'),
      orderSearch: '',
      showResult: false,
      notificationPanel: false,
      orderDate: '',
      showActiveOrder: false,
    }
  },
  computed: {
    ...mapGetters({
      userdata: 'getUser',
      orders: 'getListOfOrders',
      messages: 'getNotifications',
      online: 'getNetworkStatus',
      logoimg: 'getLogoImg',
      header_color: 'getHeaderCol'
    }),
    admin(){
      const allowedRoles = ['SUPER_ADMIN', 'ADMIN'];
      const allowedCompanies = ['PARENT', 'STORE'];
      if(this.userdata != null && allowedRoles.includes(this.userdata.user_type)
        && allowedCompanies.includes(this.userdata.company.company_type) 
      ){
        return true;
      }
      return false;
    },
    superadmin(){
      const allowedRoles = ['SUPER_ADMIN'];
      if(this.userdata != null && allowedRoles.includes(this.userdata.user_type)){
        return true;
      }
      return false;
    },
    salesStaff(){
      const allowedRoles = ['SALES_STAFF', 'SUPER_ADMIN', 'ADMIN' ];
      const allowedCompanies = ['PARENT', 'STORE', 'RETIAL']
      if(this.userdata != null && allowedRoles.includes(this.userdata.user_type)
          && allowedCompanies.includes(this.userdata.company.company_type)){
        return true;
      }
      return false;
    },
    manager(){
      const allowedRoles = ['SUPER_ADMIN', 'ADMIN'];
      const allowedCompanies = ['PARENT', 'STORE', 'RETIAL']
      
      if(this.userdata != null && allowedRoles.includes(this.userdata.user_type) && 
          allowedCompanies.includes(this.userdata.company.company_type)){
        return true;
      }
      return false;
    },
   
  },
  methods: {
     activeOrder(){
      this.showActiveOrder = !this.showActiveOrder;
    },
    animate(){
      this.animated = true
      this.toggle = !this.toggle
    },
    toggleClass: function(){
      this.show = !this.show
    },
    clear: function () {
      this.orderSearch = '';
      this.showResult = false;
      this.orderDate = '';
    },

    findNotificationURL: function (type: string) {
      if (type === 'REQUEST' || type === 'RESPONSE') {
        return '/response';
      } else if (type === 'INV_QUANTITY') {
        return '/inventory';
      }
    },

    findImageURL: function (type: string) {
      const images = require.context('../../assets/', false, /\.png$/)
      if (type === 'REQUEST') {
        return images('./email.png');
      } else if (type === 'RESPONSE') {
        return images('./arrow.png');
      } else if (type === 'INV_QUANTITY') {
        return images('./overflow.png');
      }
    },

    searchOrder: function () {
      this.$router.push({
        name: 'OrdersList',
        query: {
          invoiceId: this.orderSearch,
          date: this.orderDate
        }
      });
      this.clear();
    },

    orderDetails: function (id: number) {
      this.$router.push({
        name: 'OrderDetails',
        params: {
          orderId: id
        }
      });
      this.clear();
    },

    onlyDate: function (value: string) {
      const givenDate = new Date(value)
      return givenDate.toDateString()
    },

    ...mapActions({
      getuserdate: ActionTypes.USER_DATA,
      fetchlogo: OrderActionTypes.FETCH_LOGO_IMAGE,
      fetchOrders: OrderActionTypes.FETCH_ORDERS,
    }),

    searchTyped() {
      if (this.orderSearch == ''){
        this.showResult = false;
        return
      }
      this.fetchOrders({
        id__contains: this.orderSearch
      });
      this.showResult = true;
    },
    getCookie(name: string) {
      const value = `; ${document.cookie}`;
      const parts = value.split(`; ${name}=`);
      if (parts.length === 2){
        const cok_str = parts.pop()
        if (cok_str){
          return cok_str.split(';').shift()
        }
      }
    },

    async logout() {
      await offlineStoreService.clear();
      this.$router.push({name: 'logout'});
    }
  },
  beforeMount: async function() {
    await this.getuserdate();
    await this.fetchlogo();
    // this.$socket.emit('client_info', {id: this.userdata.id, company: this.userdata.company.id});
    const headrclr = await this.getCookie("HeaderColor") || "#e73b2a"
    this.$store.commit(MutationTypes.SetHeaderColor, headrclr)
  },
});
