
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import { ActionTypes } from '@/store/modules/auth/actions';
import Loader from '../common-components/Loader.vue';
import offlineStoreService from '@/utils/offline-store';


export default defineComponent({
  name: 'Login',
  data() {
    return {
      logo: require('../../assets/login-top-logo.svg'),
      pass: require('../../assets/login-password.svg'),
      luser: require('../../assets/login-user.svg'),
      user:require('../../assets/user-login.svg'),
      username: '',
      password: '',
      showLoader:false
    }
  },
  computed:{
    loginValidation(){
      if (this.username=='' || this.password==''){
        return 'Both fields are required!';
      }
      return false;
    },
    ...mapGetters({
      offlineMode: 'getOfflineMode',
    })
  },
  methods: {
    ...mapActions({
      loginUser: ActionTypes.LOGIN_USER,
      syncOfflineData: 'setSync',
    }),
    async submitLogin() {
      if(!this.loginValidation){
        this.showLoader=true;
        await this.loginUser({
          username: this.username,
          password: this.password
        });
        this.showLoader=false;
        offlineStoreService.intializeSync();
        if (this.offlineMode) {
          this.syncOfflineData(true);
          await offlineStoreService.initialize();
          await setTimeout(() => console.log('waiting'), 60000)
          this.syncOfflineData(false);
        }
        this.$router.push({name: 'Order'});
        return null;
      }
    },
  },
  components:{
    Loader 
  }
});
