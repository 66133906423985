
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';

import { ActionTypes } from '@/store/modules/auth/actions';
import Modal from '@/components/common-components/Modal.vue';
import Paginator from '@/components/common-components/Paginator.vue';
import { User } from '@/store/models/user';

export default defineComponent( {
  name: 'User',
  components: {
    Modal,
    Paginator
  },
  data() {
    return {
      deleteUserModal: false,
      search: '',
      userActivation: {
        id: 0,
        activate: true
      }
    }
  },
  computed: {
    ...mapGetters({
      users: 'getListOfUsers',
      counts: 'getTotalCounts',
    })
  },
  // define methods under the `methods` object
  methods: {
    clearUserActivation: function () {
      this.userActivation.id = 0;
      this.userActivation.activate = true;
    },

    setUserActivation: function (id: number, activation: boolean) {
      this.userActivation.id = id;
      this.userActivation.activate = activation;
      this.deleteUserModal = true;
    },

    closedeleteUserModal: function() {
      this.deleteUserModal = false;
      this.clearUserActivation();
    },

    searchUsers: function (event: Event) {
      if (event) {
        event.preventDefault()
      }
      this.getUsers({
        search: this.search
      });
    },

    toggleActivation: async function () {
      const user: User = {
        id: this.userActivation.id,
        is_active: !this.userActivation.activate
      };
      await this.updateUser(user);
      await this.getUsers();
      this.clearUserActivation();
      this.deleteUserModal = false;
    },

    changePage: async function (pageNo: number) {
      await this.getUsers({
        search: this.search,
        page: pageNo
      });
    },

    ...mapActions({
      getUsers: ActionTypes.GET_USERS,
      updateUser: ActionTypes.UPDATE_USER
    })
  },
  async beforeMount () {
    await this.getUsers();
  }
});
