
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';

import { Request } from '@/store/models/request';
import { ActionTypes as OrderActionTypes } from '@/store/modules/order/actions';
import { ActionTypes as AuthActionTypes } from '@/store/modules/auth/actions';
import { Order } from '@/store/models/order';
import { OrderItem } from '@/store/models/orderItem';
import Paginator from '@/components/common-components/Paginator.vue';
import { Transaction } from '@/store/models/transaction';


export default defineComponent({
  name:'Response',
  components: {
    Paginator
  },
  data(){
    return {
      request_detail:'request detail here!',
      searchStatus: 'PENDING',
      selected_request: 0,
      tab: 'Request',
      orderStatus: '',
      selected_order: 0,
      company: '',
    }
  },
  computed: {
    ...mapGetters({
      requests: 'getListOfRequests',
      user: 'getUser',
      orders: 'getListOfOrders',
      statuses: 'getOrderStatuses',
      counts: 'getTotalCountsOrderModule',
      companies: 'getInventoryCompanies',
    }),
  },
  methods:{
    requestStatuses(request: Request) {
      if (request.request_type && request.sender && request.receiver && request.status) {
        const readOnlyStatuses = ['APPROVED', 'COMPLETE']
        const isSender = typeof request.sender != 'number' && this.user.id == request.sender.id;
        const isReceiver = typeof request.receiver != 'number' && this.user.id == request.receiver.id;
        if (isSender)  {
          const currentStatuses = ['PENDING', 'CANCEL']
          if (readOnlyStatuses.includes(request.status)) return [...currentStatuses, ...readOnlyStatuses]
          return currentStatuses
        }
        if (request.request_type == 'TRANSACTION' && isReceiver) return ['PENDING', 'COMPLETE', 'CANCEL']
        else if (isReceiver) return ['PENDING', 'APPROVED', 'COMPLETE', 'CANCEL']
      }
      return []
    },

    selectRequest: function (requestId: number) {
      const request = this.requests.find((item: Request) => item.id && item.id == requestId);
      let buffer = '';
      if (request) {
        buffer+=request.id +'\r\n';
        buffer+=request.sender.username+'\r\n';
        buffer+=request.created+'\r\n';
        buffer+=request.expected_delivery_date+'\r\n';
        buffer+=request.status+'\r\n';
        buffer+=request.request_type+'\r\n';
      }
      this.request_detail=buffer+'\r\n';
      this.selected_request = requestId;
    },

    diableRequestStatus: function (requestId: number) {
      const request = this.requests.find((item: Request) => item.id && item.id == requestId);
      const unChangedStatuses = ['APPROVED', 'CANCEL',];
      if (request.status === 'COMPLETE' ||
      (this.user.company.company_type == 'RETIAL' && unChangedStatuses.includes(request.status))) {
        return true;
      }
      return false;
    },

    sendResponse: async function (requestID: number) {
      const request = this.requests.find((item: Request) => item.id && item.id == requestID);
      await this.updateRequest({
        id: request.id,
        status: request.status,
      });
    },

    onChangeStatusFilter: async function (Event?: Event, pageNo?: number) {
      if (this.user) {
        let filter = {};
        if (this.user.company.company_type == 'RETIAL') filter = {sender__company: this.user.company.id, status: this.searchStatus, page:pageNo?pageNo:1};
        else if (this.user.company.company_type == 'STORE') filter = {receiver__company: this.user.company.id, status: this.searchStatus, page:pageNo?pageNo:1};
        await this.fetchRequests(filter);
      }
    },

    datestr: function(date: string) {
      const _date = new Date(date);
      return _date.getFullYear() + '-' + _date.getMonth() + '-' + _date.getDate();
    },

    trimDecimalPlaces: function (value: string | undefined) {
      return parseFloat(value !== undefined ? value : '0.0').toFixed(2);
    },

    onOrderStatusChangeFilter: async function (Event?: Event, pageNo?: number) {
      if (this.user) {
        let filter = {};
        if (this.user.company.company_type == 'RETIAL') filter = {buyer__company: this.user.company.id, seller__company: this.company, status: this.orderStatus, page: pageNo?pageNo:1};
        else if (this.user.company.company_type == 'STORE') filter = {seller__company: this.user.company.id, buyer__company: this.company, status: this.orderStatus, page: pageNo?pageNo:1};
        await this.fetchOrders(filter);
      }
    },

    selectOrder: function (orderID: number) {
      const order = this.orders.find((item: Order) => item.id && item.id == orderID);
      let buffer = '';
      if (order) {
        buffer+=order.id +'\r\n';
        buffer+=order.seller+'\r\n';
        buffer+=order.buyer+'\r\n';
        buffer+=this.trimDecimalPlaces(order.total)+'\r\n';
        buffer+=order.created+'\r\n';
        buffer+=order.status+'\r\n';
        buffer+='Order Items:'+'\r\n';
        buffer+='BarCode \t Product Name \t Quantity \t Price \t Discount'+'\r\n';
        order.order_item.forEach((orderItem: OrderItem) => {
          if (orderItem.batch &&
          typeof orderItem.batch !== 'number' &&
          orderItem.batch.product_variant && 
          typeof orderItem.batch.product_variant !== 'number' &&
           orderItem.batch.product_variant.product && 
          typeof orderItem.batch.product_variant.product !== 'number') {
            buffer += orderItem.batch.product_variant.product.bar_code + ', ';
            buffer += orderItem.batch.product_variant.product.name + ', ';
            buffer += this.trimDecimalPlaces(orderItem.quantity) + ', ';
            buffer += this.trimDecimalPlaces(orderItem.price) + ', ';
            buffer += orderItem.discount + '\r\n';
          }
        });
      }
      this.request_detail=buffer+'\r\n';
      this.selected_order = orderID;
    },

    diableOrderStatus: function (orderID: number) {
      const request = this.orders.find((item: Order) => item.id && item.id == orderID);
      if (request.status === 'COMPLETE') {
        return true;
      }
      return false;
    },

    onChangeOrderStatus: async function (orderID: number) {
      const selectedOrder = this.orders.find((item: Order) => item.id && item.id == orderID);
      if (selectedOrder) {
        const order = {
          id: selectedOrder.id,
          status: selectedOrder.status,
        }
        await this.updateOrderStatus(order);
      }
    },

    onRequestChangePage: async function (pageNo: number) {
        await this.onChangeStatusFilter(undefined, pageNo);

    },

    onOrderChangePage: async function (pageNo: number) {
        await this.onOrderStatusChangeFilter(undefined, pageNo);
    },

    ...mapActions({
      fetchRequests: OrderActionTypes.FETCH_REQUESTS,
      updateRequest: OrderActionTypes.UPDATE_REQUEST,
      userdata: AuthActionTypes.USER_DATA,
      fetchOrders: OrderActionTypes.FETCH_ORDERS,
      fetchOrderStatuses: OrderActionTypes.FETCH_ORDER_STATUSES,
      updateOrderStatus: OrderActionTypes.UPDATE_ORDER,
      createTransaction: AuthActionTypes.CREATE_EXPENSE,
      fetchCompanies: AuthActionTypes.FETCH_ALL_COMPANIES,
    })
  },
  async beforeMount () {
    await this.userdata();
    await this.onChangeStatusFilter();
    await this.fetchOrderStatuses();
    await this.onOrderStatusChangeFilter();
    await this.fetchCompanies();
  }
});
