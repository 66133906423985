
import { defineComponent } from "vue";
import { mapActions, mapGetters } from "vuex";

import Paginator from "@/components/common-components/Paginator.vue";
import { ActionTypes } from "@/store/modules/order/actions";
import { ActionTypes as AuthActionTypes } from "@/store/modules/auth/actions";

export default defineComponent({
  name: "Inventory",
  
  components: {
    Paginator,
  },
  data() {
    return {
      searchbar: require('../../assets/search_icon.svg'),
      checkedValue: -1,
      search: "",
      company: "",
      checkall: true,
    };
  },
  computed: {
    admin() {
      const allowedRoles = ["SUPER_ADMIN", "ADMIN"];
      const allowedCompanies = ["PARENT", "STORE"];
      if (
        this.userdata != null &&
        allowedRoles.includes(this.userdata.user_type) &&
        allowedCompanies.includes(this.userdata.company.company_type)
      ) {
        return true;
      }
      return false;
    },

    ...mapGetters({
      inventory: "getInventory",
      inventoryTotal: "getInventoryTotalAmount",
      companies: "getInventoryCompanies",
      counts: "getTotalCountsOrderModule",
      userdata: "getUser",
    }),
  },
  methods: {
    onCheckChange: async function(e: any){
      this.checkedValue = e.target.value
      this.checkall = false
      await this.fetchInventory({
        company: this.checkedValue,
        search: this.search,
      });
      
    
    },

    onCheckAll: async function(e: any){
      this.checkedValue = e.target.value
      this.checkall = true
      await this.fetchInventory({
        company: this.checkedValue,
        search: this.search,
      });
    },

    onChangeCompany: async function () {
      await this.fetchInventory({
        company: this.company,
        search: this.search,
      });
     
    },

    searchInventory: async function (event: Event) {
      if (event) {
        event.preventDefault();
      }
      await this.fetchInventory({
        company: this.company,
        search: this.search,
      });
    },

    changePage: async function (pageNo: number) {
      await this.fetchInventory({
        company: this.checkedValue === -1 ? '' : this.checkedValue,
        search: this.search,
        page: pageNo,
      });
    },

    trimDecimalPlaces: function (value: string) {
      return parseFloat(value !== undefined ? value : "0.0").toFixed(2);
    },

    ...mapActions({
      fetchInventory: ActionTypes.FETCH_INVENTORY,
      fetchCompanies: AuthActionTypes.FETCH_ALL_COMPANIES,
      fetchUser: AuthActionTypes.USER_DATA,
    }),
  },
  async beforeMount() {
    await this.fetchInventory();
    await this.fetchCompanies();
    await this.fetchUser();
  }
});
