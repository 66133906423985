
import { defineComponent } from 'vue';
import { mapGetters, mapActions } from 'vuex';
import Modal from '@/components/common-components/Modal.vue';
import Paginator from '@/components/common-components/Paginator.vue';
import { ActionTypes } from '@/store/modules/auth/actions';
import { User } from '@/store/models/user';
export default defineComponent({
  name: 'Vendor',
  components: {
    Modal,
    Paginator,
  },
  data() {
    return {
      searchbar: require('../../assets/search_icon.svg'),
      deleteVendorModal: false,
      search: '',
      vendor: {
        id: 0,
        contact: '',
        firstName: '',
        lastName: ''
      }
    }
  },
  computed: {
    ...mapGetters({
      vendors: 'getListOfVendors',
      counts: 'getTotalCounts',
    })
  },
  // define methods under the `methods` object
  methods: {
    clearVendor: function () {
      this.vendor.id = 0;
      this.vendor.contact = '';
      this.vendor.firstName = '';
      this.vendor.lastName = '';
    },
    openDeleteVendorModal: function(vendor: User) {
      this.vendor.id = vendor.id ? vendor.id : 0;
      this.vendor.contact = vendor.contact_number ? vendor.contact_number : '';
      this.vendor.firstName = vendor.first_name ? vendor.first_name : '';
      this.vendor.lastName = vendor.last_name ? vendor.last_name : '';
      this.deleteVendorModal = true;
    },
    closeDeleteVendorModal: function() {
      this.deleteVendorModal = false;
      this.clearVendor();
    },
    deleteVendor: async function() {
      const user: User = {
        id: this.vendor.id,
        is_active: false
      };
      await this.updateVendor(user);
      await this.getVendors('');
      this.clearVendor();
      this.deleteVendorModal = false;
    },
    searchVendors: function (event: Event) {
      if (event) {
        event.preventDefault()
      }
      this.getVendors(this.search)
    },
    changePage: async function (pageNo: number) {
      await this.getVendors({
        search: this.search,
        page: pageNo
      });
    },
    ...mapActions({
      getVendors: ActionTypes.FETCH_VENDORS,
      updateVendor: ActionTypes.UPDATE_USER 
    })
  },
  async beforeMount () {
    await this.getVendors('');
  }
});
