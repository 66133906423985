
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';

import { ActionTypes } from '@/store/modules/order/actions';
import { Batch } from '@/store/models/batch';
import { ProductVariant } from '@/store/models/product';

export default defineComponent({
  name: 'AddEditBatch',
  props: ['productId', 'productVariantId', 'batchId'],
  data () {
    return {
      batch: {
        productVariant: 0,
        quantity: '',
        manufacturedDate: '',
        expiryDate: '',
        in_stock: true
      },
      productVariantName: '',
      showErrorProduct: false,
      showErrorQuantity:false,
      showErrorExpiryDate: false
    }
  },
  computed: {

    batchQuantityValidation: function () {
      let errorMessage = null;
      if (this.batch.quantity === undefined || this.batch.quantity === '' && this.showErrorQuantity == true)
      {
        errorMessage = 'Quantity is required';
      } else {
        const value = parseFloat(this.batch.quantity);
        if (isNaN(value) && this.showErrorQuantity == true) {
          errorMessage = 'Only numbers are allowed';
        } else {
          if ( value <= 0) {
            errorMessage = 'Quantity should be greater than zero';
          }
        }
      }
      return errorMessage;
    },

    productVariantValidation: function () {
      let errorMessage = null;
      if (this.batch.productVariant === undefined || this.batch.productVariant === 0 && this.showErrorProduct == true) {
        errorMessage = 'Product Variant is required, please select any or add product';
      }
      return errorMessage;
    },

    expiryDateValidation: function() {
      let errorMessage = null;
      if((!this.batch.manufacturedDate || !this.batch.expiryDate)
      && this.showErrorExpiryDate == true){
        errorMessage = 'Manufacture & Expiry Dates are required'
      }
      return errorMessage;
    },

    addEditBtn: function () {
      
      let disable = true;
      if ( this.batchQuantityValidation === null &&
      this.productVariantValidation === null &&
      this.expiryDateValidation === null
      ) {
        disable = false;
      }
      return disable
    },

    ...mapGetters({
      products: 'getListOfProducts'
    })
  },
  methods: {
    addUpdateBatch: async function () {
      this.showErrorQuantity = true;
      this.showErrorProduct = true;
      this.showErrorExpiryDate = true;
    
      let batchIdNumber = 0;
      if (this.batchId) {
        batchIdNumber = parseFloat(this.batchId);
        if (isNaN(batchIdNumber)) return;
      }

      const batch: Batch = {
        product_variant: this.batch.productVariant,
        quantity: this.batch.quantity,
        manufacturing_date: this.batch.manufacturedDate,
        expiry_date: this.batch.expiryDate,
        in_stock: this.batch.in_stock
      };

      if (this.productId && this.productVariantId && this.batchId && this.batchQuantityValidation == null && this.expiryDateValidation ==null) {
        batch.id = batchIdNumber;
        await this.updateBatch(batch);
        this.$router.push({name: 'Batch'});
      } else if(this.batchQuantityValidation == null && this.expiryDateValidation ==null){
        await this.createBatch(batch);
        this.$router.push({name: 'Batch'});
      }
      
    },

    loadData: function (batch: Batch) {
      this.batch.quantity = batch.quantity ? batch.quantity : '';
      this.batch.manufacturedDate = batch.manufacturing_date ? batch.manufacturing_date : '';
      this.batch.expiryDate = batch.expiry_date ? batch.expiry_date : '';
      this.batch.in_stock = batch.in_stock ? batch.in_stock : true;
    },

    ...mapActions({
      getProducts: ActionTypes.GET_PRODUCTS,
      createBatch: ActionTypes.CREATE_BATCH,
      updateBatch: ActionTypes.UPDATE_BATCH
    })
  },
  async beforeMount () {
    await this.getProducts('');

    if (this.products &&
    this.products.length > 0 &&
    this.products[0].product_variant &&
    this.products[0].product_variant.length > 0 &&
    this.products[0].product_variant[0].id) {
      this.batch.productVariant = this.products[0].product_variant[0].id;
    }

    if (this.productId && this.productVariantId && this.batchId) {
      const product_id = parseInt(this.productId);
      const product = isNaN(product_id) ? undefined : this.$store.getters.getSignleProduct(product_id);
      if (product) {
        const variant = product.product_variant.find((item: ProductVariant) => item.id == this.productVariantId);
        const batch = variant.batch.find((item: Batch) => item.id == this.batchId);
        this.productVariantName = product.name + ': ' + variant.price;
        this.batch.productVariant = variant.id;
        this.loadData(batch);
      }
      else {
        // Show 404 page on screen
        this.$router.push({name: 'notFound'});
      }
    }
  }
});
