
import { defineComponent } from 'vue'

export default defineComponent({
    props: {
        count: {
            default:20
        },
    },
    data() {
        const pageSize = process.env.VUE_APP_PAGE_SIZE as number || 30;
        return {
            records_per_page : pageSize,
            selected: 1,
            // total_pages: 5,
            list_max: 10,
            offset: 1,
        }
    },
    computed: {
        pageNo_list: function() {
            const pages: number[] = []
            for(let i=this.offset;i<this.end;++i){
                pages.push(i);
            }
            return pages;

        },
        end: function(): number {
            const new_end = this.offset + this.list_max
            return new_end >= this.total_pages? this.total_pages + 1 : new_end
        },
        start: function(): number {
            const new_start = this.offset - this.list_max
            return new_start < 1 ? 1 : new_start
        },
        total_pages(): number {
            return this.count/this.records_per_page;
        }
    },
    methods: {
        moveRight: function(reset=true){
            if(this.end < this.total_pages){
                this.offset = this.end;
            }
            if(reset)
                this.selected=this.offset;
        },
        moveLeft: function(reset=true){
            if(this.offset > 1){
                this.offset = this.start;
            }
            if(reset)
                this.selected=this.offset;
        },
        pageSelected: function(page: number) {
            this.selected = page;
        },
        next: function(){
            if (this.selected+1<this.end){
                this.selected=this.selected+1;
            }else {
                this.moveRight();
            }
        },
        prev: function(){
            if(this.selected>1){
                if (this.offset===this.selected){
                    this.moveLeft(false);
                }
                this.selected--;
            }
      
        }
    },
    watch: {
        selected: function(){
            this.$emit('pageChange', this.selected);
        }
    },
})
