
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Alert',
  props: {
    type: {
      type: String,
      default: 'info'
    }
  },
  data() {
    return {
      closed: false,
      classObject: {
        'alert--success': this.type == "success",
        'alert--error': this.type == "error",
        'alert--warning': this.type == "warning"
      }
    }
  },
  computed: {
    open: function() {
      if (this.closed) {
        return false;
      }
      return true;
    },
    
    classes: function() {
      return "1";
    }
  },
  methods: {
    close: function() {
      this.closed = true;
    }
  },
});
