
import { OrderItem } from '@/store/models/orderItem';
import { ActionTypes } from '@/store/modules/order/actions';
import { defineComponent } from 'vue';
import {mapActions, mapGetters } from 'vuex';
import printDiv from '@/utils/print';

export default defineComponent({
  name: 'OrderBill',
  data(){
		return{
			src_img: require('@/assets/rohi_logo.png'),
			printDelay: 200,
			tokenPrinting: false
		}
  },
  methods:{
		
		printBill: function() {
			const styles = `
				<style lang="scss" scoped>	
				@page {
				size: 180mm;
				margin: 0
				}
				@body {
				margin-left: 5%;
				}
				.company-info{
				display: flex;
				flex-wrap: nowrap;
				flex-direction: column;
				align-content: center;
				justify-content: center;
				align-items: center;
				font-size: 12px;
				}

				.maindiv-print {
				padding: 4px;
				max-width: 800px;
				}

				#header-section {
				display: grid;
				grid-template-columns: 1fr 2fr;
				grid-template-rows: 1fr;
				gap: 0.1em 0.1em;
				width:80mm;
				}

				#date-section {
				display: grid;
				grid-template-columns: 1fr 2fr;
				grid-template-rows: 1fr;
				gap: 0.1em 0.1em;
				width:80mm;
				}

				#order-items-section table {
				border-collapse: separate !important;
				width: 80mm;
				}

				#order-items-section th {
				border: 1px solid;
				border-color: black;
				text-align: center;
				padding: 1px;
				}

				#order-items-section td {
				border: none;
				text-align: center;
				padding: 1px;
				}

				#order-items-section tr:nth-child(even) {
				background-color: white;
				}

				#totals-section {
				width: 80mm;
				display: flex;
				justify-content: space-between;
				}

				#totals-section p {
				text-align: right;
				}

				#totals-section td {
				border: none;
				text-align: right;
				padding: 1px;
				}

				#totals-section tr:nth-child(even) {
				background-color: white;
				}

				.mb-5 {
				border-bottom: 2px solid black;
				padding-bottom: 5px;
				}

				.pt-5 {
				padding-top: 5px;
				}

				.img-responsive{
				max-width: 100%;
				height: auto;
				}

				.text-center{
				text-align: center
				}
				</style>
				`;
			printDiv("bill-preview", "", styles);
			this.checkToken();
		},

		getCurrentTime(date: Date){
			return new Date(date).toLocaleTimeString();
		},

		getCurrentDate(date: Date){
			return new Date(date).toDateString();
		},

		getItemTotal: function(price: string, quantity: string): string {
			return (parseFloat(price)*parseFloat(quantity)).toFixed(4);
		},

		getProductName: function(orderItem: any) {
			return orderItem.batch.product_variant.product.name;
		},

		getProductSize: function(orderItem: any) {
			return orderItem.batch.product_variant.size;
		},

		getOperatorFullName: function() {
			const firstname = this.operator.first_name!==undefined?this.operator.first_name:'';
			const lastname = this.operator.last_name!==undefined?this.operator.last_name:'';
			return `${firstname} ${lastname}`;
		},

		getCustomerFullName: function() {
			const firstname = this.customer.first_name!==undefined?this.customer.first_name:'';
			const lastname = this.customer.last_name!==undefined?this.customer.last_name:'';
			return `${firstname} ${lastname}`;
		},

		trimNumber: function(value: string) {
			return parseFloat(value).toFixed(2);

		},
		trimAmount: function(value: string) {
			return parseFloat(value).toFixed(4);

		},

    checkToken: function () {
      if (this.order.order_item) {
        for (const orderItem of this.order.order_item) {
          if (orderItem.batch.product_variant.product.token) {
            this.printToken(orderItem, this.order.created)
          }
        }
      }
    },

    printToken: function (orderItem: OrderItem, created: Date) {
      if (orderItem.price &&
      orderItem.quantity &&
      orderItem.batch &&
      typeof orderItem.batch !== 'number' &&
      orderItem.batch.product_variant &&
      typeof orderItem.batch.product_variant !== 'number' &&
      orderItem.batch.product_variant.product &&
      typeof orderItem.batch.product_variant.product !== 'number' &&
        orderItem.batch.product_variant.product.name) {
        const styles = `
        <style>
        @page {
          size: 80mm;
          margin: 0
        }
        .company-info{
          display: flex;
          flex-wrap: nowrap;
          flex-direction: column;
          align-content: center;
          justify-content: center;
          align-items: center;
        }

        .maindiv-print {
          padding: 4px;
          max-width: 800px;
        }

        #header-section {
          display: grid;
          grid-template-columns: 1fr 2fr;
          grid-template-rows: 1fr;
          gap: 0.1em 0.1em;
        }

        #date-section {
          display: grid;
          grid-template-columns: 1fr 2fr;
          grid-template-rows: 1fr;
          gap: 0.1em 0.1em;
        }

        #order-items-section table {
          border-collapse: separate;
        }

        #order-items-section th {
          border: 1px solid black;
          text-align: center;
          padding: 1px;
        }

        #order-items-section td {
          border: none;
          text-align: center;
          padding: 1px;
        }

        #order-items-section tr:nth-child(even) {
          background-color: white;
        }

        #totals-section {
          display: grid;
          grid-template-columns: 1fr 1fr;
          grid-template-rows: 1fr;
          gap: 0.1em 0.1em;
        }

        #totals-section p {
          text-align: right;
        }

        #totals-section td {
          border: none;
          text-align: right;
          padding: 1px;
        }

        #totals-section tr:nth-child(even) {
          background-color: white;
        }

        .mb-5 {
          border-bottom: 2px solid black;
          padding-bottom: 5px;
        }
        </style>
        `;
        const printContents = `
        <div id="TokenPreview">
          <p class="text-center" style="font-size: 20px; text-align: center;"><strong>Rohi Sweets & Bakers</strong></p>
          <p class="text-center" style="margin: 10px 0px 10px; text-align: center;">
            <span style="border: 1px solid black; padding: 2px 20px;">30806</span>
          </p>
          <div id="order-items-section">
            <table>
              <colgroup>
                <col span="1" style="width: 20%;">
                <col span="1" style="width: 70%;">
                <col span="1" style="width: 10%;">
              </colgroup>

              <tr>
                <th>قیمت</th>
                <th style="text-align: right;">تفصیل</th>
                <th>تعداد</th>
              </tr>

              <tr>
                <td>${this.trimNumber(orderItem.price)}</td>
                <td style="text-align: right;">${orderItem.batch.product_variant.product.name}</td>
                <td>${this.trimNumber(orderItem.quantity)}</td>
              </tr>
            </table>
          </div>
          <hr class="dashed">
          <div class="mb-5">${this.getItemTotal(orderItem.price, orderItem.quantity)}</div>
          <div id="date-section">
            <p class="text-center">${this.getCurrentTime(created)}</p>
            <p class="text-center">${this.getCurrentDate(created)}</p>
          </div>
        </div>
        `;
        printDiv(printContents,"", styles);
      }
    },

    sleep: function (ms: number) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },

		...mapActions({
			fetchOrder: ActionTypes.FETCH_ORDER,
			clearOrder: ActionTypes.CHANGE_ORDER_STATUS
		}),
    },
	computed:{
		total: function(): string {
			let sum = 0;
			(this.order.order_item).map((item: any) => {
				sum = sum + parseFloat(item.price?item.price:'0')*parseFloat(item.quantity?item.quantity:'0')
			})
			return sum.toFixed(2);
		},

		change: function(): string {
			return (parseFloat(this.order.amount_received) - parseFloat(this.order.total)).toFixed(2);
		},

		...mapGetters({
			order: 'getOrder',
			operator: 'getUser',
			logoimg: 'getLogoImg',

		})
	},
	props:{
		user: {},
		orderId: {
			default: 0,
		},
		customer: {
			default: {
				first_name:'',
				last_name:'',
				user_type: 'WALK_IN_CUSTOMER',
			}
		},
    print: {
      default: true,
    },
	},
	mounted: function () {
    if (this.print) {
      this.printBill();
    }
	},
	beforeMount: async function() {
		await this.fetchOrder(this.orderId);
	},
	unmounted: async function() {
		await this.clearOrder();
	}
});
