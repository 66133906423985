
import { defineComponent } from "vue";
import { mapActions, mapGetters } from "vuex";
import OrderBill from "@/components/sales/OrderBill.vue";
import Modal from "@/components/common-components/Modal.vue";
import { ActionTypes as AuthActionTypes } from "@/store/modules/auth/actions";
import { ActionTypes as OrderActionTypes } from "@/store/modules/order/actions";
import { Order } from "@/store/models/order";
import { Batch } from "@/store/models/batch";
import { User } from "@/store/models/user";
import { OrderItem } from "@/store/models/orderItem";
import { Product, ProductVariant } from "@/store/models/product";
import offlineStoreService from "@/utils/offline-store/index";
import OfflineOrderBill from "../sales/OfflineOrderBill.vue";

export default defineComponent({
  name: "ZeroOrder",
  components: {
    Modal,
    OrderBill,
    OfflineOrderBill,
  },
  data() {
    const today = new Date().toDateString();
    const orderItems: OrderItem[] = [];
    const batches: Batch[] = [];
    const vendor: User = {};
    const productName: string[] = [];

    return {
      latestOrder: {},
      orderoffline: false,
      submitOrderBtnDisable: false,
      focusedTile: -1,
      focusedID: "",
      cancelModal: false,
      product: {
        name: "",
        barCode: "",
        quantity: "",
        batch: "",
        quantityUpperLimit: 0,
        discount: "",
        buyPrice: "",
        actualPrice: 0,
        manufacturedDate: new Date().toISOString().slice(0, 10),
        expiryDate: "",
      },
      date: today,
      orderItems: orderItems,
      productId: 0,
      productVariantId: 0,
      productBatchSelect: batches,
      cashReceived: "",
      totalDiscount: "",
      orderType: "from",
      errorIndication: true,
      BarCodeMaxLength: 48,
      ProductNameMaxLength: 60,
      duplicateMessage: "",
      creditPaymentMethod: "cash",
      discountMethod: "amount",

      addVendorModal: false,
      seller: 0,
      buyer: 0,
      vendorUser: vendor,

      vendor: {
        firstName: "",
        lastName: "",
        contact: "",
        company: 0,
      },
      showErrorSeller: false,
      showErrorBuyer: false,
      showErrorVenCont: false,
      showErrorVenComp: false,
      productName: productName,
      return_order: false,
    };
  },
  computed: {
    totalAmount: function (): number {
      let total = this.orderItems
        // eslint-disable-next-line
        .map((item: any) => {
          if (typeof item.totalPrice === "string") {
            return parseFloat(item.totalPrice);
          }
          return item.totalPrice;
        })
        .reduce((a: number, b: number) => a + b, 0);

      const totalDiscount = parseFloat(this.totalDiscount);
      if (this.discountMethod === "amount") {
        if (!isNaN(totalDiscount) && totalDiscount > 0) {
          total = total - totalDiscount;
        }
      } else if (this.discountMethod === "percentage") {
        if (
          !isNaN(totalDiscount) &&
          totalDiscount > 0 &&
          totalDiscount <= 100
        ) {
          total = total * ((100 - totalDiscount) / 100);
        }
      }
      return total;
    },

    cashReturned: function (): number | null {
      const value = parseFloat(this.cashReceived);
      return isNaN(value) ? null : value - this.totalAmount;
    },

    productNameValidation: function () {
      let errorMessage = null;
      if (!this.errorIndication) {
        // CheckIF product exist on the backend
        if (this.product.name === undefined || this.product.name === "") {
          errorMessage = "Name is required";
        }
      }
      return errorMessage;
    },

    productBarCodeValidation: function () {
      let errorMessage = null;
      if (!this.errorIndication) {
        // CheckIF product exist on the backend
        if (this.product.barCode === undefined || this.product.barCode === "") {
          errorMessage = "Barcode is required";
        }
      }
      return errorMessage;
    },

    productQuantityValidation: function () {
      let errorMessage = null;
      if (!this.errorIndication) {
        // CheckIF product exist on the backend
        if (
          this.product.quantity === undefined ||
          this.product.quantity === ""
        ) {
          errorMessage = "Quantity is required";
        } else {
          const value = parseFloat(this.product.quantity);
          if (isNaN(value)) {
            errorMessage = "Only numbers are allowed";
          } else if (
            this.orderType === "to" &&
            value > this.selectedBatchQuantity
          ) {
            errorMessage = "Stock in this batch is less than quantity.";
          }
        }
      }
      return errorMessage;
    },

    productBatchValidation: function () {
      let errorMessage = null;
      if (!this.errorIndication) {
        // CheckIF product exist on the backend
        if (this.product.batch === undefined || this.product.batch === "") {
          errorMessage = "Batch is required";
        }
      }
      return errorMessage;
    },

    productDiscountValidation: function () {
      let errorMessage = null;
      if (!this.errorIndication) {
        // CheckIF product exist on the backend
        if (
          this.product.discount !== undefined &&
          this.product.discount !== ""
        ) {
          const value = parseFloat(this.product.discount);
          if (isNaN(value)) {
            errorMessage = "Only numbers are allowed";
          } else {
            if (value < 0 || value > 100) {
              errorMessage = "Discount value should be b/w 0 to 100";
            }
          }
        }
      }
      return errorMessage;
    },

    productManufacturedValidation: function () {
      let errorMessage = null;
      if (!this.errorIndication) {
        // CheckIF product exist on the backend
        if (this.product.manufacturedDate === undefined) {
          errorMessage = "Manufactured date is required";
        }
      }
      return errorMessage;
    },

    productExpiryValidation: function () {
      let errorMessage = null;
      if (!this.errorIndication) {
        // CheckIF product exist on the backend
        if (
          this.product.expiryDate === undefined ||
          this.product.expiryDate === ""
        ) {
          errorMessage = "Expiry date is required";
        }
      }
      return errorMessage;
    },

    addProductButton: function (): boolean {
      let disable = true;
      if (
        this.productNameValidation === null &&
        this.productBarCodeValidation === null &&
        this.productQuantityValidation === null &&
        this.validateReturnOrder === null
      ) {
        disable = false;
      }
      return disable;
    },

    orderTotalDiscountValidation: function () {
      let errorMessage = null;
      if (this.totalDiscount !== undefined && this.totalDiscount !== "") {
        const value = parseFloat(this.totalDiscount);
        if (isNaN(value)) {
          errorMessage = "Only numbers are allowed";
        } else {
          if (this.discountMethod === "amount" && value < 0) {
            errorMessage = "Discount should be greater than zero";
          } else if (
            this.discountMethod === "percentage" &&
            (value < 0 || value > 100)
          ) {
            errorMessage = "Discount value should be b/w 0 to 100";
          }
        }
      }
      return errorMessage;
    },

    orderCashReceivedValidation: function () {
      let errorMessage = null;
      if (this.return_order && this.cashReceived !== undefined && this.cashReceived !== "") {
        const value = parseFloat(this.cashReceived);
        if (isNaN(value)) {
          errorMessage = "Only numbers are allowed";
        }
      }
      return errorMessage;
    },

    submitOrderButton: function () {
      let disable = true;
      if (
        this.orderItems.length > 0 &&
        this.orderTotalDiscountValidation === null &&
        this.orderCashReceivedValidation === null &&
        this.submitOrderBtnDisable === false && 
        this.sellerValidation === null && this.buyerValidation === null
      ) {
        disable = false;
      }
      return disable;
    },

    selectedBatchQuantity: function (): number {
      let selectedBatchQuantity = 0.0;
      if (this.orderType === "to") {
        const batchID = parseInt(this.product.batch);
        if (
          !isNaN(batchID) &&
          batchID > 0 &&
          this.productBatchSelect.length > 0
        ) {
          const selectedBatch = this.productBatchSelect.find(
            (item: Batch) => item.id === batchID
          );
          selectedBatchQuantity =
            selectedBatch && selectedBatch.inventory_quantity
              ? selectedBatch.inventory_quantity
              : 0;
        }
      }
      return selectedBatchQuantity;
    },

    orderTypeValidation: function () {
      let disable = false;
      if (this.orderItems.length > 0) {
        disable = true;
      }
      return disable;
    },

    contactNumberValidation: function () {
      let errorMessage = null;
      if (this.vendor.contact.length <= 0 && this.showErrorVenCont===true) {
        errorMessage = "Number is required";
      }
      return errorMessage;
    },

    companyValidation: function () {
      let errorMessage = null;
      if (this.companies.length <= 0 && this.showErrorVenComp===true) {
        errorMessage = "Comapny is required. Add vendor comapany to system";
      }
      return errorMessage;
    },

    variantsflatList: function () {
      const variants: { ProductId: any; VariantId: any }[] = [];
      this.productResult.map((item: Product) => {
        if (item) {
          const listofvariants = item.product_variant as ProductVariant[];
          listofvariants.map((variant: ProductVariant) => {
            variants.push({ ProductId: item.id, VariantId: variant.id });
          });
        }
      });
      return variants;
    },
    sellerValidation: function () {
      let errorMessage = null;
      if (this.orderType === "from" && this.seller === 0 && this.showErrorSeller === true) {
          errorMessage = "Seller is required";
        }
      return errorMessage;
    },
    buyerValidation: function () {
      let errorMessage = null;
      if (this.orderType === "to" && this.buyer === 0 && this.showErrorBuyer === true) {
          errorMessage = "Buyer is required";
        }
      return errorMessage;
    },
    validateReturnOrder: function () {
      let error_message = null;
      if (this.return_order && parseFloat(this.product.quantity) >= 0) {
        error_message = "quantity should be in negative for return order!";
      }

      return error_message;
    },
    ...mapGetters({
      productResult: "getProductResults",
      userdata: "getUser",
      orderStatus: "getOrderStatus",
      users: "getListOfUsers",
      vendors: "getListOfVendors",
      newBatch: "getBatch",
      companies: "getCompanies",
      invoiceID: "getInvoiceID",
      order_response: "getOrder",
    }),
  },
  methods: {
    clearProduct: function () {
      this.productId = 0;
      this.productVariantId = 0;
      this.product.barCode = "";
      this.product.name = "";
      this.product.quantity = "";
      this.product.discount = "";
      this.product.batch = "";
      this.product.quantityUpperLimit = 0;
      const batches: Batch[] = [];
      this.productBatchSelect = batches;
      this.errorIndication = true;
      this.duplicateMessage = "";
      this.product.buyPrice = "";
      this.product.actualPrice = 0;
      this.product.manufacturedDate = new Date().toISOString().slice(0, 10);
    },

    trimNumber: function (value: string): string {
      return parseFloat(value !== undefined ? value : "0.0").toFixed(2);
    },

    orderTypeChange: function () {
      if (this.orderType === "to") {
        this.buyer = 0;
        this.seller = this.userdata.id;
      } else if (this.orderType === "from") {
        this.seller = 0;
        this.buyer = this.userdata.id;
      }
    },
    returnOrderChange: function () {
      this.return_order = !this.return_order
      if (this.return_order) {
        this.orderType = "to"
        this.orderTypeChange();
      } else {
        this.orderType = "from"
        this.orderTypeChange();
      }
    },

    selectProduct: async function (productId: number, VariantId: number) {
      this.duplicateMessage = "";
      const currentProduct = await this.productResult.find(
        (item: Product) => item.id === productId
      );
      const currentVariant = await currentProduct.product_variant.find(
        (item: ProductVariant) => item.id === VariantId
      );

      // Check If the product is already in Order Items
      const duplicate = await this.orderItems.find(
        (item: OrderItem) => item.product && item.product === currentProduct
      );

      if (duplicate) {
        this.duplicateMessage =
          "The product is already added to the order items.";
        return;
      }

      this.duplicateMessage = "";
      this.productId = productId;
      this.productVariantId = VariantId;
      this.product.barCode = currentProduct.bar_code;
      this.product.name = currentProduct.name;
      this.product.actualPrice = parseFloat(currentVariant.price);
      this.productBatchSelect = currentVariant.batch
        .filter(
          (batch: Batch) =>
            batch.inventory_quantity && batch.inventory_quantity > 0
        )
        // eslint-disable-next-line
        .sort((x: any, y: any) => +new Date(x.created) - +new Date(y.created));
        this.productBatchSelect = this.productBatchSelect.sort((a, b) => a > b?1:-1);
      const batch =
        this.productBatchSelect.length > 0
          ? (this.productBatchSelect[0] as Batch).id
          : undefined;
      this.product.batch = batch !== undefined ? batch.toString() : "";
      if (this.orderType == "to") {
        (
          this.$refs.batches as HTMLSelectElement & { focus: () => void }
        ).focus();
      } else {
        (
          this.$refs.quantity as HTMLSelectElement & { focus: () => void }
        ).focus();
      }
    },

    addOrderItem: async function () {
      this.errorIndication = false;
      let quantity = + parseFloat(this.product.quantity).toFixed(4);
      quantity = isNaN(quantity) ? 0 : quantity;
      let price = parseFloat(this.product.buyPrice);
      price = isNaN(price) ? 0 : price;

      if (this.product.name === "") return;
      if (this.product.barCode === "") return;
      if (this.product.quantity === "") return;
      if (this.product.buyPrice === "") return;
      if (this.orderType === "to" && this.product.batch === "") return;
      if (this.orderType === "to" && quantity > this.selectedBatchQuantity)
        return;
      if (
        this.orderType === "from" &&
        this.product.manufacturedDate === undefined
      )
        return;
      if (this.orderType === "from" && this.product.expiryDate === "") return;

      const discount = isNaN(parseFloat(this.product.discount))
        ? 0
        : parseFloat(this.product.discount);

      if (
        (!this.productId || this.productId === 0) &&
        (!this.productVariantId || this.productVariantId === 0)
      ) {
        this.duplicateMessage =
          "Please select product from product results or add desired product to system";
        return;
      }
      this.duplicateMessage = "";

      const currentProduct = await this.productResult.find(
        (item: Product) => item.id === this.productId
      );
      const currentVariant = await currentProduct.product_variant.find(
        (item: ProductVariant) => item.id === this.productVariantId
      );

      price = currentVariant.price;
      let totalPrice = price * quantity;
      if (this.product.discount && discount > 0 && discount < 100) {
        totalPrice = totalPrice * ((100 - discount) / 100);
      }
      totalPrice = + parseFloat(totalPrice.toString()).toFixed(4)
      let batch;
      if (this.orderType === "to") {
        batch = currentVariant.batch
          .map((item: Batch) => {
            return {
              id: item.id,
              manufacturing_date: item.manufacturing_date,
              expiry_date: item.expiry_date,
              quantity: item.quantity,
              in_stock: item.in_stock,
              product_variant: item.product_variant,
              created: item.created,
              last_modified: item.last_modified,
            } as Batch;
          })
          .find(
            (item: Batch) =>
              item && item.id && item.id.toString() == this.product.batch
          );
      } else {
        batch = {
          manufacturing_date: this.product.manufacturedDate.toString(),
          expiry_date: this.product.expiryDate,
          quantity: quantity.toString(),
          product_variant: this.productVariantId,
        } as Batch;
      }
      const SingleOrderItem: OrderItem = {
        batch: batch,
        product: currentProduct,
        productVariant: currentVariant,
        price: price.toString(),
        quantity: quantity.toString(),
        discount: discount.toString(),
        totalPrice,
      };
      this.productName.push(this.product.name);
      this.orderItems.push(SingleOrderItem);
      this.clearProduct();
      (this.$refs.barcode as HTMLInputElement & { focus: () => void }).focus();
    },

    submitOrder: async function () {
      this.submitOrderBtnDisable = true;
      this.showErrorSeller = true;
      if(this.orderType === "from"){
        this.showErrorSeller = true;
      }else{
        this.showErrorBuyer = true;
      }

      if (this.orderItems.length < 0) return;
      if (this.buyer === 0 || this.seller === 0) return;


      const unproxiedOrderItems = await this.orderItems.map(
        (singleOrderItem: OrderItem) => {
          const unproxiedOrderItem = {
            batch: singleOrderItem.batch,
            price: singleOrderItem.price?.toString(),
            discount: singleOrderItem.discount?.toString(),
            quantity: singleOrderItem.quantity?.toString(),
          } as OrderItem;
          return unproxiedOrderItem;
        }
      );

      if(await offlineStoreService.isInternetConnectionWorking()){
        for (const singleOrderItem of unproxiedOrderItems) {
        if (singleOrderItem.batch) {
          if (this.orderType == "from") {
            await this.createBatch(singleOrderItem.batch);
            singleOrderItem.batch = this.newBatch.id;
          } else if (
            this.orderType == "to" &&
            typeof singleOrderItem.batch !== "number"
          ) {
            singleOrderItem.batch = singleOrderItem.batch.id;
          }
        }
      }
      }
      else{
        for (const singleOrderItem of unproxiedOrderItems) {
          if (singleOrderItem.batch) {
            if (this.orderType == "from"){
              if (typeof singleOrderItem.batch !== "number"){
                  const batch = {
                  manufacturing_date: singleOrderItem.batch.manufacturing_date?.toString(),
                  expiry_date: singleOrderItem.batch.expiry_date?.toString(),
                  quantity: singleOrderItem.batch.quantity,
                  product_variant: singleOrderItem.batch.product_variant?.toString(),
                } as Batch;
                singleOrderItem.order_batch = batch;
                singleOrderItem.batch = undefined;
                
              }
            }
            else if (
            this.orderType == "to" &&
            typeof singleOrderItem.batch !== "number"
          ) {
            singleOrderItem.batch = singleOrderItem.batch.id;
          }
          }
        }
      }
      
      const cash = parseFloat(this.cashReceived);
      const discount = parseFloat(this.totalDiscount);

      const singleOrder: Order = {
        order_item: unproxiedOrderItems,
        buyer: this.buyer,
        seller: this.seller,
        total_discount: isNaN(discount) ? "0" : discount.toString(),
        total: parseFloat(this.totalAmount.toString()).toFixed(4),
        amount_received: isNaN(cash) ? "0" : cash.toString(),
        amount_discount: this.discountMethod === "amount" ? true : false,
        invoice_id: this.invoiceID,
        internal_order: true,
      };
      if (this.return_order) {
        singleOrder.status = "RETURNED";
      }
      if(await offlineStoreService.isInternetConnectionWorking() === false){
        this.orderoffline = true;
        this.latestOrder = singleOrder;
      }
      await this.createOrder(singleOrder);
      this.submitOrderBtnDisable = false;
      this.showErrorSeller = false;
      this.showErrorBuyer = false;
    },

    changeQuantity: function (index: number) {
      const currentVariant = this.orderItems[index].productVariant;
      if (currentVariant !== undefined) {
        const currentOrderItemQuantity = this.orderItems[index].quantity;
        const currentOrderItemPrice = this.orderItems[index].price;
        const currentDiscount = this.orderItems[index].discount;
        const currentBatch = this.orderItems[index].batch;

        if (
          currentOrderItemQuantity !== undefined &&
          currentOrderItemPrice !== undefined &&
          currentBatch !== undefined &&
          typeof currentBatch !== "number"
        ) {
          const quantity = parseFloat(currentOrderItemQuantity);
          const price = parseFloat(currentOrderItemPrice);
          const discount = currentDiscount ? parseFloat(currentDiscount) : 0;
          const upperLimit = currentBatch.inventory_quantity
            ? currentBatch.inventory_quantity
            : 0;

          if (isNaN(price)) return;
          if (isNaN(quantity)) return;
          if (quantity > upperLimit) return;
          let total = quantity * price;
          if (!((isNaN(discount) && discount <= 0) || discount > 100)) {
            total = total * ((100 - discount) / 100);
          }
          this.orderItems[index].totalPrice = parseInt(total.toFixed(0));
        }
      }
    },

    changeDiscount: function (index: number) {
      const currentOrderItemDiscount = this.orderItems[index].discount;
      const currentOrderItemPrice = this.orderItems[index].price;
      const currentOrderItemQuantity = this.orderItems[index].quantity;
      if (
        currentOrderItemDiscount !== undefined &&
        currentOrderItemPrice !== undefined &&
        currentOrderItemQuantity !== undefined
      ) {
        const quantity = parseFloat(currentOrderItemQuantity);
        const price = parseFloat(currentOrderItemPrice);
        const discount = parseFloat(currentOrderItemDiscount);

        if ((isNaN(discount) && discount <= 0) || discount > 100) return;
        this.orderItems[index].totalPrice = parseInt(
          (price * quantity * ((100 - discount) / 100)).toFixed(0)
        );
      }
    },

    changePrice: function (index: number) {
      const currentOrderItemPrice = this.orderItems[index].price;
      const totalPrice = this.orderItems[index].totalPrice;
      if (totalPrice !== undefined && currentOrderItemPrice !== undefined) {
        const price = parseFloat(currentOrderItemPrice);

        if (isNaN(price)) return;
        if (totalPrice < 0) return;

        this.orderItems[index].quantity = (totalPrice / price)
          .toFixed(4)
          .toString();
        this.orderItems[index].discount = (0.0).toString();
      }
    },

    removeItem: function (index: number) {
      this.orderItems.splice(index, 1);
    },

    searchByName: function (event: Event) {
      if (event) {
        event.preventDefault();
      }
      this.searchProductByName(this.product.name);
    },

    searchByBarcode: async function (event: Event) {
      
      await this.searchProductByBarcode(this.product.barCode);

      if (this.productResult.length === 1) {
        const searchedProduct: Product = this.productResult[0];
        if (
          searchedProduct.id &&
          searchedProduct.product_variant &&
          searchedProduct.product_variant.length > 0 &&
          searchedProduct.product_variant[0].id
        )
          await this.selectProduct(
            searchedProduct.id,
            searchedProduct.product_variant[0].id
          );
      }
    },

    sumQuantity: function (item: ProductVariant): number {
      let sum = 0;
      if (item.batch !== undefined && typeof item.batch !== "number") {
        item.batch
          .filter(
            (batch: Batch) =>
              batch.inventory_quantity && batch.inventory_quantity > 0
          )
          .forEach((batch: Batch) => {
            if (batch.inventory_quantity !== undefined) {
              sum = sum + batch.inventory_quantity;
            }
          });
      }
      return sum;
    },

    handleOrderStatus: async function () {
      this.changeOrderStatus("");
      this.clearProduct();
      await this.searchProductByBarcode(""); //this statement will clear the search results from action
      this.orderItems = [];
      this.cashReceived = "";
      this.totalDiscount = "";
      this.orderType = "from";
      this.return_order = false;
      this.seller = 0;
      this.buyer = this.userdata.id;
      const vendor: User = {};
      this.vendorUser = vendor;
      this.cancelModal = false;
      await this.fetchInvoiceID();
      await this.getUsers("ADMIN");
      await this.getVendors("");
      if(await offlineStoreService.isInternetConnectionWorking() === false){
        this.orderoffline = false;
      }
    },

    changeProductPrice: function () {
      let quantity = 0;
      if (this.product.actualPrice > 0 && this.product.buyPrice) {
        const price = parseFloat(this.product.buyPrice);

        if (!isNaN(price)) {
          quantity = price / this.product.actualPrice;
        }
      }
      this.product.quantity = quantity.toString();
    },

    changeProductQuantity: function () {
      let buyPrice = 0;
      if (this.product.actualPrice > 0 && this.product.quantity) {
        const quantity = parseFloat(this.product.quantity);

        if (!isNaN(quantity)) {
          buyPrice = quantity * this.product.actualPrice;
        }
      }
      this.product.buyPrice = buyPrice.toString();
    },

    sellerVendorCheck: function () {
      if (this.seller) {
        this.vendorUser = this.vendors.find(
          (item: User) => item.id === this.seller
        );
      }
    },
    buyerVendorCheck: function () {
      if (this.buyer) {
        this.vendorUser = this.vendors.find(
          (item: User) => item.id === this.buyer
        );
      }
    },

    addVendor: async function () {
      this.showErrorVenCont = true;
      this.showErrorVenComp= true;
      if(this.contactNumberValidation == null && this.companyValidation == null){
        const user: User = {
          first_name: this.vendor.firstName,
          last_name: this.vendor.lastName,
          username: this.vendor.contact,
          contact_number: this.vendor.contact,
          company: this.vendor.company,
          is_active: true,
          user_type: "VENDOR",
        };

        await this.registerUser(user);
        await this.getVendors("");
      }
    },

    openAddVendorModal: async function () {
      this.addVendorModal = true;
      this.vendor.firstName = "";
      this.vendor.lastName = "";
      this.vendor.contact = "";
      this.vendor.company = 0;
      this.showErrorVenCont = false;
      this.showErrorVenComp = false;
      if (this.companies && this.companies.length > 0) {
        this.vendor.company = this.companies[0].id;
      }
    },

    checkkey: function (event: KeyboardEvent) {
      const variantslist = this.variantsflatList;
      if (event.key === "ArrowDown") {
        this.focusedTile++;
        if (variantslist.length <= this.focusedTile) {
          this.focusedTile = 0;
        }
        if (variantslist.length > 0) {
          const focused = variantslist[this.focusedTile];
          const refid = focused.ProductId + "_" + focused.VariantId;
          this.focusedID = refid;
          this.fixScrolling();
          (
            this.$refs[refid] as HTMLSelectElement & { focus: () => void }
          )?.focus();
        }
      } else if (event.key === "Enter") {
        if (this.focusedTile < variantslist.length) {
          const focused = variantslist[this.focusedTile];
          this.selectProduct(focused.ProductId, focused.VariantId);
        }
      } else if (event.key === "ArrowUp") {
        this.focusedTile--;
        if (0 > this.focusedTile) {
          this.focusedTile = 0;
        }
        if (variantslist.length > 0) {
          const focused = variantslist[this.focusedTile];
          const refid = focused.ProductId + "_" + focused.VariantId;
          this.focusedID = refid;
          this.fixScrolling();
          (
            this.$refs[refid] as HTMLSelectElement & { focus: () => void }
          )?.focus();
        }
      }
    },
    shiftfocusTo: function (event: KeyboardEvent, to: string, key = "Enter") {
      if (event.key === key) {
        (this.$refs[to] as HTMLSelectElement & { focus: () => void })?.focus();
      }
    },

    fixScrolling() {
      const liH = (this.$refs.options as HTMLElement).clientHeight;
      (this.$refs.scrollContainer as any).scrollTop = liH * this.focusedTile;
    },


    ...mapActions({
      searchProductByName: OrderActionTypes.SEARCH_PRODUCT_BY_NAME,
      searchProductByBarcode: OrderActionTypes.SEARCH_PRODUCT_BY_BARCODE,
      createOrder: OrderActionTypes.CREATE_ORDER,
      changeOrderStatus: OrderActionTypes.CHANGE_ORDER_STATUS,
      getUsers: AuthActionTypes.GET_All_USERS,
      getVendors: AuthActionTypes.FETCH_VENDORS,
      createBatch: OrderActionTypes.CREATE_BATCH,
      registerUser: AuthActionTypes.REGISTER_USER,
      fetchCompanies: AuthActionTypes.FETCH_COMPANIES,
      fetchInvoiceID: OrderActionTypes.FETCH_INVOICE_ID,
    }),
  },
  async beforeMount() {
    await this.fetchInvoiceID();
    await this.getUsers("ADMIN");
    await this.getVendors("");
    await this.fetchCompanies({
      company_type: "VENDOR",
      search: "",
    });
    this.buyer = this.userdata.id;
    if(await offlineStoreService.isInternetConnectionWorking()){
      await offlineStoreService.Order();
      await offlineStoreService.initialize()
    }
  },
});
