
import { mapActions, mapGetters } from 'vuex';
import { defineComponent } from 'vue';
import Modal from "./Modal.vue";

export default defineComponent({
  name: 'ErrorHandler',
  components: {
    Modal
  },
  computed: {
    ...mapGetters(['getError'])
  },
  methods: {
    dismissError: function () {
      this.setError(null);
    },
  
    ...mapActions(['setError'])
  }
})
