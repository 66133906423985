
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';

import { ActionTypes } from '@/store/modules/order/actions';
import Modal from '@/components/common-components/Modal.vue';
import Paginator from '@/components/common-components/Paginator.vue';
import { Batch } from '@/store/models/batch';

export default defineComponent({
  name: 'Batch',
  components: {
    Modal,
    Paginator,
  },
  data() {
    return {
      deleteBatchModal: false,
      search: '',
      batch: {
        id: 0,
        productName: '',
        quantity: '',
        manufacturedDate: '',
        expiryDate: ''
      }
    }
  },
  computed: {
    ...mapGetters({
      products: 'getListOfProducts',
      totalCount: 'getTotalCountsBatches'
    })
  },
  // define methods under the `methods` object
  methods: {
    clearDeleteBatch: function () {
      this.batch.id = 0;
      this.batch.productName = '';
      this.batch.quantity = '';
      this.batch.manufacturedDate = '';
      this.batch.expiryDate = '';
    },

    closeDeleteBatchModal: function () {
      this.deleteBatchModal = false;
      this.clearDeleteBatch();
    },

    openDeleteBatchModal: function (productName: string, batch: Batch) {
      this.deleteBatchModal = true;
      this.batch.id = batch && batch.id ? batch.id : 0;
      this.batch.productName = productName ? productName : '';
      this.batch.quantity = batch && batch.quantity ? batch.quantity : '';
      this.batch.manufacturedDate = batch && batch.manufacturing_date ? batch.manufacturing_date : '';
      this.batch.expiryDate = batch && batch.expiry_date ? batch.expiry_date : '';
    },

    deleteBatchYes: async function () {
      if (this.batch && this.batch.id) {
        await this.deleteBatch(this.batch.id.toString());
        await this.getProducts();
        this.deleteBatchModal = false;
        this.clearDeleteBatch();
      }
    },

    searchProducts: function (event: Event) {
      if (event) {
        event.preventDefault()
      }
      this.getProducts(this.search);
    },

    changePage: async function (pageNo: number) {
      await this.getBatchesByPage({
        page: pageNo,
      });
    },
    ...mapActions({
      getProducts: ActionTypes.GET_PRODUCTS,
      deleteBatch: ActionTypes.DELETE_BATCH,
      getbatches: ActionTypes.GET_BATCHES,
      getBatchesByPage: ActionTypes.GET_BATCHES_BY_PAGE,
    })
  },
  async beforeMount () {
    await this.getProducts('');
    await this.getbatches('');
  }
});
