
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'modal',
  props: {
    type: {
      type: String,
    }
  },
  data() {
    return {
      classObject: {
        'scroll':  this.type == 'scrollable',
        
      },
      classObject2: {
        'center-align': this.type == 'scrollable',
      }
    }
  }
});
