
import { defineComponent } from 'vue';
import Modal from '@/components/common-components/Modal.vue';
import Paginator from '@/components/common-components/Paginator.vue';
import { mapActions, mapGetters } from 'vuex';
import { ActionTypes } from '@/store/modules/order/actions';
import { Category, Product } from '@/store/models/product';

export default defineComponent({
  name: 'Product',
  components: {
    Modal,
    Paginator
  },
  data() {
    return {
      searchbar: require('../../assets/search_icon.svg'),
      search: '',
      deleteProductModal: false,
      deleteProduct: {
        id: '',
        name: '',
        barcode: ''
      }
    }
  },
  computed: {
    ...mapGetters({
      productsList: 'getListOfProducts',
      userdata: 'getUser',
      productsCount: 'getProductsCount',
      categories: 'getCategories',
    }),
    allowedAddProduct(){
      const allowedRoles = ['ADMIN','SUPER_ADMIN'];
      const rol: string = this.userdata!=null ? this.userdata.user_type : '';
      return (rol!==null && allowedRoles.includes(rol));
    }
  },
  // define methods under the `methods` object
  methods: {
    clearDeleteProduct: function () {
      this.deleteProduct.id = '';
      this.deleteProduct.name = '';
      this.deleteProduct.barcode = '';
    },

    getCategoryName(id: number) {
      const cate = this.categories.find((cate: Category) => cate && cate.id && 
      typeof cate.id === 'number' && cate.id == id);
      return cate ? cate.name : '-';
    },

    closeDeleteProductModal: function () {
      this.deleteProductModal = false;
      this.clearDeleteProduct();
    },

    OpenDeleteProductModal: function (product: Product) {
      this.deleteProductModal = true;
      this.deleteProduct.id = product && product.id ? product.id.toString() : '';
      this.deleteProduct.name = product && product.name ? product.name.toString() : '';
      this.deleteProduct.barcode = product && product.bar_code ? product.bar_code.toString() : '';
    },

    searchProducts: function (event: Event) {
      if (event) {
        event.preventDefault()
      }
      this.getProducts(this.search)
    },

    deleteProductYes: async function () {
      if (this.deleteProduct && this.deleteProduct.id) {
        await this.deleteProductAction(this.deleteProduct.id?.toString());
        await this.getProducts();
        this.deleteProductModal = false;
        this.clearDeleteProduct();
      }
    },

    changePage: async function (pageNo: number) {
      await this.getProductsByPage({
        page: pageNo,
      });
    },

    ...mapActions({
        getProducts: ActionTypes.GET_PRODUCTS,
        deleteProductAction: ActionTypes.DELETE_PRODUCT,
        getProductsByPage: ActionTypes.GET_PRODUCTS_BY_PAGE,
        fetchCategories: ActionTypes.FETCH_CATEGORIES,
    })
  },
  async beforeMount () {
    await this.getProducts('');
    await this.fetchCategories();
  }
});
