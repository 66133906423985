
import { mapActions, mapGetters } from 'vuex';
import { defineComponent } from 'vue';

import { ActionTypes } from '@/store/modules/order/actions';

export default defineComponent({
  name: 'EditOrder',
  props: ['orderId'],
  watch: {
    $route(to, from) {
      this.emptyOrder();
    },
  },
  computed: {
    cashRetured: function(): number {
      let cash = 0;
      if (this.order && this.order.amount_received && this.order.total) {
        cash = parseFloat(this.order.amount_received) - parseFloat(this.order.total)
      }
      return cash;
    },

    readonlyData: function() {
      if (this.orderId) {
        return true;
      } else {
        return false;
      }
    },

    ...mapGetters({
      order: 'getOrder'
    })
  },
  methods: {
    orderItemTotal: function (quantity: string, price: string) {
      return parseFloat(quantity) * parseFloat(price);
    },

    onlyDate: function (value: string) {
      const givenDate = new Date(value)
      return givenDate.toDateString()
    },

    ...mapActions({
      fetchOrder: ActionTypes.FETCH_ORDER,
      emptyOrder: ActionTypes.EMPTY_ORDER,
    })
  },
  async created () {
    if (this.orderId) {
      const order_id = parseInt(this.orderId);
      await this.fetchOrder(order_id);
    }
  },
});
