
import { computed, defineComponent, toRaw } from "vue";
import { mapActions, mapGetters } from "vuex";
import { ActionTypes } from "@/store/modules/order/actions";
import { ActionTypes as AuthActionTypes } from "@/store/modules/auth/actions";
import { BarChart } from "vue-chart-3";
import { Chart, registerables } from "chart.js";
import { store } from "@/store";
import printDiv from '@/utils/print';
import PrintHeader from '@/components/common-components/PrintHeader.vue'
import {styles2 as styles} from '@/constants/analytics_print'
import chartConfig from "@/constants";
Chart.register(...registerables);

export default defineComponent({
  name: "ComparisonAnalysis",
  components: {
    BarChart,
    PrintHeader,
  },
  setup() {
    const testData = {
      datasets: [
        {
          data: JSON.parse(JSON.stringify(store.getters.getComparisonanalysis)),
        },
        
      ],
    };
    const options = {
      parsing: {
        xAxisKey: "company_name",
        yAxisKey: "profit",
      },
    };
    return { testData, options };
  },
  data() {
    const date = new Date();
    const dateStr = `${date.getFullYear()}-${
      date.getMonth() + 1
    }-${date.getDate()}`;

    return {
      src_img: require('@/assets/DefoultLogoAvatar-01.png'),
      chartData: {},
      startDate: "2022-01-01",
      endDate: "2022-05-20",
      company: 0,
      company_type: "",
      companies_types: [
        { name: "FACTORY", value: "factory" },
        { name: "RETIAL", value: "retial" },
      ],
      printDelay: 100,
    };
  },
  computed: {
    ...mapGetters({
      salesanalytics: "getSalesanalytics",
      userdata: "getUser",
      comparisonAnalysis: "getComparisonanalysis",
      logoimg: 'getLogoImg',
    }),
  },
  methods: {
    ...mapActions({
      getComparisonAnalysis: ActionTypes.FETCH_COMPARISON_ANALYSIS,
    }),
    admin() {
      const allowedRoles = ["SUPER_ADMIN", "ADMIN"];
      const allowedCompanies = ["PARENT", "STORE"];
      if (
        this.userdata != null &&
        allowedRoles.includes(this.userdata.user_type) &&
        allowedCompanies.includes(this.userdata.company.company_type)
      ) {
        return true;
      }
      return false;
    },
    async fetchAnalyticsComparisonBtn() {
      await this.getComparisonAnalysis({
        company_type: this.company,
      });
      this.chartData = {
        labels: [],
        datasets: [
          {
            label: "Companies",
            data: JSON.parse(
              JSON.stringify(store.getters.getComparisonanalysis)
            ),
            backgroundColor: ['#27aeef', '#b33dc6', '#f46a9b', '#e60049'],
            borderColor: ['#27aeef', '#b33dc6', '#f46a9b', '#e60049'],
          },
        ],
      };
    },
    printDiv(div_id: string, title: string) {
      const canvas = document.getElementById('bar-chart') as HTMLCanvasElement;
      const img = canvas.toDataURL('image/png')
      const graph_div = document.getElementById('bar-chart') as HTMLBodyElement
      const graph_img = document.getElementById('graph-img') as HTMLImageElement
      graph_img.src = img
      console.log(img)
      printDiv(div_id, title, styles);
    },
    
  },
});
