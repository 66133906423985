
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';

import { ActionTypes } from '@/store/modules/auth/actions';
import { User } from '@/store/models/user';
import ErrorField from '@/components/common-components/ErrorField.vue';

export default defineComponent({
  name: 'AddEditUser',
  props: ['userId'],
  components: {
    ErrorField,
  },
  data () {
    return {
      img_file: require('@/assets/rohi_logo.png'),
      f_time: true,
      ab_error_message: '',
      ps_error_message: '',
      cp_error_message: '',
      num_error_message: '',
      company_errormessage: '',
      user: {
        userName: '',
        password: '',
        confirmPassword: '',
        firstName: '',
        lastName: '',
        email: '',
        active: true,
        contactNumber: '',
        user_type: 'SALES_STAFF',
        company: 0,
        user_profilepic: '',
      },
      user_type: "",
    }
  },
  computed: {
    userNameValidation: function () {
      let errorMessage = null;
      const re = /^[a-zA-Z0-9@.+\-_]{3,150}$/;
      if (this.user.userName.length > 150) {
        errorMessage = "Only 150 characters or fewer are allowed";
      }else if (this.user.userName.length != 0 && !re.test(this.user.userName)) {
        errorMessage = "Minimum three characters and only letters, digits and these character (@ . + - _) are allowed"
      }
      return errorMessage;
    },

    passwordValidation: function () {
      let errorMessage = null;
      if (!this.userId || (this.userId && this.user_type=='ADMIN')) {
        const re = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;
        if (this.user.password.length != 0 && !re.test(this.user.password)) {
          errorMessage = "Minimum eight characters, at least one letter, one number and one special character (@$!%*#?&)"
        }
      }
      return errorMessage;
    },

    ConfirmPasswordValidation: function () {
      let errorMessage = null;
      if (!this.userId || (this.userId && this.user_type=='ADMIN')) {
        if (this.user.confirmPassword.length != 0 && this.user.password && this.user.password !== this.user.confirmPassword) {
          errorMessage = "Confirm Password does not matches with password"
        }
      }
      return errorMessage;
    },

    emailValidation: function () {
      let errorMessage = null;
      if (this.user.email && !this.validEmail(this.user.email)) {
        errorMessage = "Enter a valid email"
      }
      return errorMessage;
    },

    contactNumberValidation: function () {
      let errorMessage = null;
      const regex = /^((\+92)|(0092))-{0,1}\d{3}-{0,1}\d{7}$|^\d{11}$|^\d{3,5}-{0,1}\d{7}$/;
      if (this.user.contactNumber.length != 0 && !regex.test(this.user.contactNumber)) {
        errorMessage = "Phone number is not valid"
      }
      return errorMessage;
    },
    ...mapGetters({
      roles: 'getRoles',
      companies: 'getCompaniesFilterVendor',
      fieldErrors: 'getAuthFieldError',
      userdata: 'getUser',
    })
  },
  methods: {
    checkAllValidation: function(){
      // validate
      if (this.user.userName.length <= 0) {
        this.ab_error_message = "User Name is required";
      }
      else {
        this.ab_error_message = ""
      }
      if (this.user.password.length <= 0 ) {
        this.ps_error_message = "Password is required"
      }
      else {
        this.ps_error_message = ""
      }
      if (this.user.confirmPassword.length <= 0) {
        this.cp_error_message = "Confirm Password is required" 
      }
      else {
        this.cp_error_message = ""
      }
      if (this.user.contactNumber.length <= 0) {
        this.num_error_message = "Number is required"
      }
      else {
        this.num_error_message = ""
      }
      if (this.user.company === 0) {
        this.company_errormessage = "Comapny is required. Add Comapany to system"
      }
      else{
        this.company_errormessage = ""
      }
  
      if ( this.userNameValidation === null &&
          this.passwordValidation === null &&
          this.ConfirmPasswordValidation === null &&
          this.emailValidation === null &&
          this.contactNumberValidation === null &&      
          this.ab_error_message === "" && 
          this.ps_error_message === "" &&
          this.cp_error_message === "" && this.num_error_message === "" && this.company_errormessage === ""){
            return true
          }
      else{
        return false
      }
    },
    loadImage(event: any) {
      this.img_file = URL.createObjectURL(event.target.files[0])
      this.user.user_profilepic = event.target.files[0]
      this.f_time = false    
    },
    isUrl(url: any) {
      const regexp = /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/ // eslint-disable-line
      return regexp.test(url);
    },
    fillformData(){
      const formData = new FormData();
      formData.append('username', this.user.userName);
      formData.append('first_name', this.user.firstName);
      formData.append('last_name', this.user.lastName);
      formData.append('email', this.user.email);
      formData.append('is_active', JSON.stringify(this.user.active));
      formData.append('user_type', this.user.user_type);
      formData.append('company', JSON.stringify(this.user.company));
      formData.append('contact_number', this.user.contactNumber);
      formData.append('user_profilepic', this.user.user_profilepic);
      return formData
    },
    addUpdateUser: async function () {
      let userIdNumber = 0;
      if (this.userId) {
        userIdNumber = parseFloat(this.userId);
        if (isNaN(userIdNumber)) return;
      }

      const user: User = {
        username: this.user.userName,
        first_name: this.user.firstName,
        last_name: this.user.lastName,
        email: this.user.email,
        is_active: this.user.active,
        is_staff: true,
        user_type: this.user.user_type,
        contact_number: this.user.contactNumber,
        user_profilepic: this.user.user_profilepic
      }
      const userformData = this.fillformData()
      
      if (this.userId) {
        
        if (this.contactNumberValidation === null && this.num_error_message === ""){
          user.id = userIdNumber;
          userformData.append('id', JSON.stringify(userIdNumber));
          if (this.isUrl(userformData.get('user_profilepic'))){
            userformData.delete('user_profilepic')
          } 
          await this.updateUser({'updUser': userformData, 'id': user.id});
          if (Object.keys(this.fieldErrors).length === 0) {
            this.$router.push({name: 'User'});
          } else {
            window.scrollTo(0,0);
          }
          if(this.user_type=='ADMIN' &&  this.user.password != "" ){
            user.password = this.user.password;
            await this.updateUser({'updUser': userformData, 'id': user.id});
            if (Object.keys(this.fieldErrors).length === 0) {
              this.$router.push({name: 'User'});
            } else {
              window.scrollTo(0,0);
            }
          }
          else{
            await this.updateUser({'updUser': userformData, 'id': user.id});
            if (Object.keys(this.fieldErrors).length === 0) {
              this.$router.push({name: 'User'});
            } else {
              window.scrollTo(0,0);
            }
          }
        }
      } 
      else {
        if (this.checkAllValidation())
          {
            user.company = this.user.company,
            user.password = this.user.password;
            await this.registerUser(userformData);
            if (Object.keys(this.fieldErrors).length === 0) {
              this.$router.push({name: 'User'});
            } else {
              window.scrollTo(0,0);
            }
          }
      }
    },

    loadData: function (user: User) {
      this.user.firstName = user.first_name ? user.first_name : '';
      this.user.lastName = user.last_name ? user.last_name : '';
      this.user.email = user.email ? user.email : '';
      this.user.userName = user.username ? user.username : '';
      this.user.active = user.is_active ? user.is_active : false;
      this.user.user_type = user.user_type ? user.user_type : '';
      this.user.company = user.company && typeof user.company !== 'number' && user.company.id ? user.company.id : 0;
      this.user.contactNumber = user.contact_number ? user.contact_number : '';
      this.user.user_profilepic = user.user_profilepic ? user.user_profilepic : '';
    },

    validEmail: function (email: string): boolean {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },

    ...mapActions({
      registerUser: ActionTypes.REGISTER_USER,
      fetchTypes: ActionTypes.FETCH_TYPES,
      fetchCompanies: ActionTypes.FETCH_COMPANIES,
      updateUser: ActionTypes.UPDATE_USER,
      getUsersList: ActionTypes.GET_USERS,
      setFieldError: ActionTypes.SET_FIELD_ERROR,
    })
  },
  async beforeMount () {
    await this.fetchTypes();
    await this.fetchCompanies();

    if (this.companies && this.companies.length > 0) {
      // this.user.company = this.companies[0].id;
    }

    if (this.userId) {
      await this.getUsersList();
      const user_id = parseInt(this.userId);
      const user = isNaN(user_id) ? undefined : this.$store.getters.getSignleUser(user_id);
      if (user) {
        this.loadData(user);
      }
      else {
        // Show 404 page on screen
        this.$router.push({name: 'notFound'});
      }
    }
  },
  async unmounted () {
    await this.setFieldError({});
  },
  mounted () {
    this.user_type = this.userdata['user_type'];
  }
});
